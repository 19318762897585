import React from 'react'
import { Box, Container, Group, Text, BackgroundImage, } from '@mantine/core'

import { Blocks } from '../../content/Blocks'
import { BlocksShort } from '../../content/BlocksShort'

export const SectionTwo: React.FC = () => {

const SectionImage = require('../../image/photo.jpg')
  return (
    <Box w={'100%'} h={'max-content'} bg={'#FFF'} id='company' className='sec-2-main'>
        <Container maw={1180} pb={'4rem'}>
            <Box mt={'4rem'} className='sec-2-title'>
                <Text ff={'Roboto Condensed'} lh={'normal'} lts={2} fz={42} fw={700} color='#10222E'>О компании</Text>
                <Blocks />
            </Box>
            <Group position='apart' mt={'4rem'} h={'max-content'} className='sec-2-group'>
                <Box w={'max-content'} h={337} className='sec-2-box'>
                    <Text maw={351} ff={'Roboto'} fz={18} lh={'normal'} mt={'3rem'} color='#10222E' className='sec-2-text'>
                        Добро пожаловать в мир мебели,
                        где мебель – <b>это результат творчества нашего коллектива,</b>
                        <br />
                        и каждый клиент – <b>наша главная ценность</b>
                    </Text>
                    <Text maw={351} ff={'Roboto'} fz={18} lh={'normal'} mt={52} color='#10222E' className='sec-2-text'>
                        Наше внимание к деталям и стремление к совершенству делают нас вашим надежным партнером в создании стильной и функциональной мебели
                    </Text>
                </Box>
                <BackgroundImage src={SectionImage} h={337} w={580} className='sec-2-image'></BackgroundImage>
            </Group>
            <BlocksShort />
            {/* <Group mt={'2rem'} pb={'4rem'} h={'max-content'} position='apart' className='sec-2-group'>
                <Box w={480} h={450} bg={'gray'} className='sec-2-block'></Box>
                <Box w={680} h={450} bg={'gray'} className='sec-2-block'></Box>
            </Group> */}
        </Container>
    </Box>
  )
}
