import React from 'react';
import './App.css';
import { Routes, Route } from 'react-router-dom';
// import { HomePage } from './pages/HomePage';
// import { KitchenPage } from './pages/KitchenPage';
// import { TablesPage } from './pages/TablesPage';
// import { ShkafPage } from './pages/ShkafPage';
// import { GarderobPage } from './pages/GarderobPage';
// import { GostinayaPage } from './pages/GostinayaPage';
// import { ToiletPage } from './pages/ToiletPage';
// import { StoleshnitsaPage } from './pages/StoleshnitsaPage';
import { RouteComponent } from './routes/routeComponent';
function App() {
  return (
    <RouteComponent />
  );
}

export default App;
