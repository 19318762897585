const stylia = [...Array(24)].map((_,i) => require(`../../image/cataloge/tables/stylia/${i}.png`))
const barnye = [...Array(4)].map((_,i) => require(`../../image/cataloge/tables/barnye/${i}.jpg`))
const poofs = [...Array(2)].map((_,i) => require(`../../image/cataloge/tables/poofs/${i}.jpg`))
const stoly = [...Array(13)].map((_,i) => require(`../../image/cataloge/tables/stoly/${i}.jpg`))

export const TableData = [
    {
        id: 0,
        title: 'Стол',
        imgURL: stoly[0]
    },
    {
        id: 1,
        title: 'Стол',
        imgURL: stoly[1]
    },
    {
        id: 2,
        title: 'Стол',
        imgURL: stoly[2]
    },
    {
        id: 3,
        title: 'Стол',
        imgURL: stoly[3]
    },
    {
        id: 4,
        title: 'Стол',
        imgURL: stoly[4]
    },
    {
        id: 5,
        title: 'Стол',
        imgURL: stoly[5]
    },
    {
        id: 6,
        title: 'Стол',
        imgURL: stoly[6]
    },
    {
        id: 7,
        title: 'Стол',
        imgURL: stoly[7]
    },
    {
        id: 8,
        title: 'Стол',
        imgURL: stoly[8]
    },
    {
        id: 9,
        title: 'Стол',
        imgURL: stoly[9]
    },
    {
        id: 10,
        title: 'Стол',
        imgURL: stoly[10]
    },
    {
        id: 11,
        title: 'Стол',
        imgURL: stoly[11]
    },
    {
        id: 12,
        title: 'Стол',
        imgURL: stoly[12]
    },
]

export const Table2Data = [
    {
        id: 0,
        title: 'Турин',
        imgURL: stylia[0]
    },
    {
        id: 1,
        title: 'Пенелопа',
        imgURL: stylia[1]
    },
    {
        id: 2,
        title: 'Парма',
        imgURL: stylia[2]
    },
    {
        id: 3,
        title: 'Модена',
        imgURL: stylia[3]
    },
    {
        id: 4,
        title: 'Ирис',
        imgURL: stylia[4]
    },
    {
        id: 5,
        title: 'Денвер',
        imgURL: stylia[5]
    },
    {
        id: 6,
        title: 'Шарм',
        imgURL: stylia[6]
    },
    {
        id: 7,
        title: 'Франклин',
        imgURL: stylia[7]
    },
    {
        id: 8,
        title: 'Торрес',
        imgURL: stylia[8]
    },
    {
        id: 9,
        title: 'Сидней',
        imgURL: stylia[9]
    },
    {
        id: 10,
        title: 'Саваж',
        imgURL: stylia[10]
    },
    {
        id: 11,
        title: 'Румба',
        imgURL: stylia[11]
    },
    {
        id: 12,
        title: 'Палермо',
        imgURL: stylia[12]
    },
    {
        id: 13,
        title: 'Палермо',
        imgURL: stylia[13]
    },
    {
        id: 14,
        title: 'Осло',
        imgURL: stylia[14]
    },
    {
        id: 15,
        title: 'Модерн',
        imgURL: stylia[15]
    },
    {
        id: 16,
        title: 'Колумб',
        imgURL: stylia[16]
    },
    {
        id: 17,
        title: 'Кассиопия',
        imgURL: stylia[17]
    },
    {
        id: 18,
        title: 'Кальяри',
        imgURL: stylia[18]
    },
    {
        id: 19,
        title: 'Дуглас',
        imgURL: stylia[19]
    },
    {
        id: 20,
        title: 'Дублин',
        imgURL: stylia[20]
    },
    {
        id: 21,
        title: 'Джулия',
        imgURL: stylia[21]
    },
    {
        id: 22,
        title: 'Версаль',
        imgURL: stylia[22]
    },
    {
        id: 23,
        title: 'Честер',
        imgURL: stylia[23]
    },
]

export const BarnyeData = [
    {
        id: 0,
        title: 'Барный стул',
        imgURL: barnye[0]
    },
    {
        id: 1,
        title: 'Барный стул',
        imgURL: barnye[1]
    },
    {
        id: 2,
        title: 'Барный стул',
        imgURL: barnye[2]
    },
    {
        id: 3,
        title: 'Барный стул',
        imgURL: barnye[3]
    },
]

export const PoofsData = [
    {
        id: 0,
        title: 'Пуф',
        imgURL: poofs[0]
    },
    {
        id: 1,
        title: 'Пуф',
        imgURL: poofs[1]
    },
]