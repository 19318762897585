import React from "react";
import { Routes, Route } from "react-router-dom";
import { data } from "./routesData";

export const RouteComponent: React.FC = (type: any) => {
    return (
        <Routes>
            {data.map((item) => (
                <Route key={item.id} path={item.path} element={item.element} />
            ))}
        </Routes>
    )
}