import React, { useEffect } from 'react'
import { useLocation } from 'react-router-dom'

import { SectionOne } from '../components/MainPage/SectionOne'
import { SectionTwo } from '../components/MainPage/SectionTwo'
import { SectionThree } from '../components/MainPage/SectionThree'
import { SectionFour } from '../components/MainPage/SectionFour'
import { SectionFive } from '../components/MainPage/SectionFive'
import { SectionSeven } from '../components/MainPage/SectionSeven'
import { Footer } from '../components/Footer'
import { ContinuousTextAnimation } from '../components/animation/ContinuousTextAnimation'
import { SectionSix } from '../components/MainPage/SectionSix'

const componentsData = [
  {id: 1, title:<SectionOne />},
  {id: 2, title:<SectionTwo />},
  {id: 3, title:<ContinuousTextAnimation />},
  {id: 4, title:<SectionThree />},
  {id: 5, title:<ContinuousTextAnimation />},
  {id: 6, title:<SectionFour />},
  {id: 7, title:<ContinuousTextAnimation />},
  {id: 8, title:<SectionFive />},
  {id: 9, title:<SectionSix />},
  {id: 10, title:<SectionSeven />},
  {id: 11, title:<Footer />},
]

interface PropsComponent {
    title: JSX.Element,
    id: number
}

const PropsContainer: React.FC<PropsComponent> = ({title, id}) => {
  return title
}

const Container: React.FC = () => {
  return (
    <>
    {componentsData.map((item) => (
      <PropsContainer title={item.title} key={item.id} id={item.id} />
    ))}
    </>
  )
}

export const HomePage: React.FC = (type: any) => {
  const location = useLocation()

    useEffect(() => {
        
        if (location.hash) {
          const targetElement = document.querySelector(location.hash);
          if (targetElement) {
            targetElement.scrollIntoView({ behavior: 'smooth' });
          }
        }
      }, [location]);
      
  return (
    <>
      <Container />
    </>
  )
}



