import { HomePage } from '../pages/HomePage';
import { KitchenPage } from '../pages/KitchenPage';
import { TablesPage } from '../pages/TablesPage';
import { ShkafPage } from '../pages/ShkafPage';
import { GarderobPage } from '../pages/GarderobPage';
import { GostinayaPage } from '../pages/GostinayaPage';
import { ToiletPage } from '../pages/ToiletPage';
import { AvarusPage } from '../pages/stones/AvarusPage';
import { ProjectDetails } from '../pages/ProjectDetails';
import { Avarus } from '../components/Stoleshnitsa/Avarus';
import { StoleshnitsaPage } from '../pages/StoleshnitsaPage';
import { GrandexPage } from '../pages/stones/GrandexPage';
import { Grandex } from '../components/Stoleshnitsa/Grandex';
import { Avant } from '../components/Stoleshnitsa/Avant';
import { AvantPage } from '../pages/stones/AvantPage';

export const data = [
    {
        id: 0,
        path: '/',
        element: <HomePage />
    },
    {
        id: 1,
        path: '/kitchen',
        element: <KitchenPage />
    },
    {
        id: 2,
        path: '/tables',
        element: <TablesPage />
    },
    {
        id: 3,
        path: '/shkaf',
        element: <ShkafPage />
    },
    {
        id: 4,
        path: '/garderob',
        element: <GarderobPage />
    },
    {
        id: 5,
        path: '/gostinaya',
        element: <GostinayaPage />
    },
    {
        id: 6,
        path: '/stoleshnitsa/avarus',
        element: <AvarusPage />
    },
    {
        id: 7,
        path: '/san-yzli',
        element: <ToiletPage />
    },
    {
        id: 8,
        path: 'portfolio/:pathURL',
        element: <ProjectDetails />
    },
    {
        id: 9,
        path: 'stoleshnitsa/avarus/:pathURL',
        element: <Avarus/>
    },
    {
        id: 10,
        path: '/stoleshnitsa',
        element: <StoleshnitsaPage />
    },
    {
        id: 11,
        path: '/stoleshnitsa/grandex',
        element: <GrandexPage />
    },
    {
        id: 12,
        path: '/stoleshnitsa/grandex/:pathURL',
        element: <Grandex />
    },
    {
        id: 13,
        path: '/stoleshnitsa/avant/:pathURL',
        element: <Avant />
    },
    {
        id: 14,
        path: '/stoleshnitsa/avant',
        element: <AvantPage />
    }
]