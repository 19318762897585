const photos = [...Array(3)].map((_, i) => require(`../../image/cataloge/garderob/${i}.jpg`))

export const GarderobData = [
    {
        id: 0,
        title: 'Гардероб',
        imgURL: photos[0]
    },
    {
        id: 1,
        title: 'Гардероб',
        imgURL: photos[1]
    },
    {
        id: 2,
        title: 'Гардероб',
        imgURL: photos[2]
    },
]