import React from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import { Box, Flex, Text } from '@mantine/core';
import './ContinuousTextAnimation.css'; // Подключаем файл стилей

const TextData = new Array(1)

for (let i = 0; i < 64; i++) {
  const text_1 = {id: i, title: <Text fz={18} color='#FFF' ff={'Roboto Condensed'} lh={'normal'} ml={'4rem'}>{i % 2 === 0 ? 'Студия мебели' : 'Amalya home'}</Text>}
  TextData.push(text_1)
}

interface ComponentProps {
  id: number,
  title: JSX.Element,
}

const PropsContainer: React.FC<ComponentProps> = ({ title }) => {
    return title
}

const TextContainer: React.FC = () => {
    return (
        <Flex mt={'1.5rem'}>
          {TextData.map((item) => (
            <PropsContainer key={item.id} id={item.id} title={item.title} />
          ))}
        </Flex>
    )
}

const texts = [
    <TextContainer />,
]; // Список текстов для анимации

export const ContinuousTextAnimation = () => {
  const [index, setIndex] = React.useState(0);

  React.useEffect(() => {
    const interval = setInterval(() => {
      setIndex((prevIndex) => (prevIndex + 1) % texts.length);
    }, 10000); // Интервал для смены текста (в миллисекундах)

    return () => clearInterval(interval);
  }, []);

  return (
    <Box className="animation-container" w={'100%'} bg={'#6C725A'} sx={{overflow: 'hidden'}}>
      <AnimatePresence>
        <motion.div
          key={index}
          initial={{ x: '-30%' }}
          animate={{ x: '-17.5%' }}
          exit={{ x: '-17.5%' }}
          transition={{ duration: 24, ease: 'linear', repeat: Infinity, }}
          className="text-animation"
        >
          {texts[index]}
        </motion.div>
      </AnimatePresence>
    </Box>
  );
}

