import { Box, Group, Text } from "@mantine/core"
import { IconChevronUp } from "@tabler/icons-react"

export const UpButton: React.FC = () => {
    return (
        <Box component='a' href='#' w={'auto'} h={'auto'} bg={'#10222E'} m={'1rem'} sx={{
            position: 'fixed',
            bottom: 0,
            left: 0,
            zIndex: 1000,
            textDecoration: 'none',
            '&:hover': {
              backgroundColor: '#73303F'
            }
            }}>
              <Group position='left' p={'xs'}>
                <IconChevronUp color='#FFF' size={'1.5rem'}/>
                <Text ff={'Roboto Condensed'} color='#FFF' lh={'normal'} lts={2} className='sec-1-btn-text'>
                  Наверх сайта
                </Text>
              </Group>
          </Box>
    )
}