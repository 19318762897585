export const dataHeader = [
    {id: 0, title: 'Индивидуальный подход на всех этапах заказа от проектирования до сдачи объекта под ключ.'},
    {id: 1, title: 'Мы всегда на связи и готовы выслушать все пожелания и воплотить их в ваших проектах. '},
    {id: 2, title: 'Свяжитесь с нами - и ваши идеи обретут реальность'},
]

export const dataSectionFour = [
    {id: 0, title: 'Мебель с использованием современных  материалов , изготовленную на современном оборудовании  с соблюдением всех технологических требований'},
    {id: 1, title: 'Установку мебели и при необходимости подключение техники  профессиональными сборщиками'},
    {id: 2, title: 'Грамотные рекомендации по подготовке помещения к приему и размещению мебели (электрике, сантехнике, вентиляции …)'},
    {id: 3, title: 'Фиксированные цены на продукцию при заключении договора и внесении авансового платежа'},
    {id: 4, title: 'Гарантию на мебель 5 лет - Мы гарантируем высокое качество нашей мебели'}
]

// const projectZeroPhotos = [...Array(1)].map((_,i) => require(`../image/portfolio/0/${i}.png`))
// const projectOnePhotos = [...Array(2)].map((_,i) => require(`../image/portfolio/1/${i}.png`))
// const projectTwoPhotos = [...Array(2)].map((_,i) => require(`../image/portfolio/2/${i}.png`))
// const projectThreePhotos = [...Array(1)].map((_,i) => require(`../image/portfolio/3/${i}.png`))
// const projectFourPhotos = [...Array(4)].map((_,i) => require(`../image/portfolio/4/${i}.png`))
// const projectFivePhotos = [...Array(7)].map((_,i) => require(`../image/portfolio/5/${i}.png`))
// const projectSixPhotos = [...Array(4)].map((_,i) => require(`../image/portfolio/6/${i}.png`))
// const projectSevenPhotos = [...Array(3)].map((_,i) => require(`../image/portfolio/7/${i}.png`))
// const projectEightPhotos = [...Array(1)].map((_,i) => require(`../image/portfolio/8/${i}.png`))
// const projectNinePhotos = [...Array(3)].map((_,i) => require(`../image/portfolio/9/${i}.png`))
// const projectTenPhotos = [...Array(6)].map((_,i) => require(`../image/portfolio/10/${i}.png`))
// const projectElevenPhotos = [...Array(3)].map((_,i) => require(`../image/portfolio/11/${i}.png`))
// const projectTwelvePhotos = [...Array(8)].map((_,i) => require(`../image/portfolio/12/${i}.png`))
// const projectThirteenPhotos = [...Array(5)].map((_,i) => require(`../image/portfolio/13/${i}.png`))
// const projectFourteenPhotos = [...Array(3)].map((_,i) => require(`../image/portfolio/14/${i}.png`))
// const projectFiveteenPhotos = [...Array(5)].map((_,i) => require(`../image/portfolio/15/${i}.png`))
// const projectSixteenPhotos = [...Array(4)].map((_, i) => require(`../image/portfolio/16/${i}.png`))

function createProjectPhotosArray(projectIndex: number, photoCount: number) {
    return [...Array(photoCount)].map((_, i) => require(`../image/portfolio/${projectIndex}/${i}.png`));
}

const projectPhotos = [];

projectPhotos[0] = createProjectPhotosArray(0, 1);
projectPhotos[1] = createProjectPhotosArray(1, 2);
projectPhotos[2] = createProjectPhotosArray(2, 2);
projectPhotos[3] = createProjectPhotosArray(3, 1);
projectPhotos[4] = createProjectPhotosArray(4, 4);
projectPhotos[5] = createProjectPhotosArray(5, 7);
projectPhotos[6] = createProjectPhotosArray(6, 4);
projectPhotos[7] = createProjectPhotosArray(7, 3);
projectPhotos[8] = createProjectPhotosArray(8, 1);
projectPhotos[9] = createProjectPhotosArray(9, 3);
projectPhotos[10] = createProjectPhotosArray(10, 6);
projectPhotos[11] = createProjectPhotosArray(11, 3);
projectPhotos[12] = createProjectPhotosArray(12, 8);
projectPhotos[13] = createProjectPhotosArray(13, 5);
projectPhotos[14] = createProjectPhotosArray(14, 3);
projectPhotos[15] = createProjectPhotosArray(15, 5);
projectPhotos[16] = createProjectPhotosArray(16, 4);
projectPhotos[17] = createProjectPhotosArray(17, 2)

export const dataSectionFive = [
    {
        id: 0,
        title: 'Мебель для кухни',
        material: 'МДФ, покрытая глянцевой эмалью',
        description: 'Со столешницей из кварцевого агломерата Калаката',
        photoURL: projectPhotos[0][0],
        photoArray: [...projectPhotos[0]],
        pathURL: '0',
    },
    {
        id: 1,
        title: 'Кухня "Амалия"',
        material: 'Выполнена в белоснежной эмали с тонкой фрезеровкой по периметру фасада.',
        description: '',
        photoURL: projectPhotos[1][0],
        photoArray: [...projectPhotos[1]],
        pathURL: '1',
    },
    {
        id: 2,
        title: 'Шкафы в постирочную',
        material: 'Открывание slim line Hettich',
        description: '',
        photoURL: projectPhotos[2][0],
        photoArray: [...projectPhotos[2]],
        pathURL: '2',
    },
    {
        id: 3,
        title: 'Кухня в современном стиле',
        material: 'Суперматовый белоснежный Fenix и древесный пластик Willsonart',
        description: '',
        photoURL: projectPhotos[3][0],
        photoArray: [...projectPhotos[3]],
        pathURL: '3',
    },
    {
        id: 4,
        title: 'Кухня лофт',
        material: 'Alvic sinhron низ древесный фундермакс',
        description: '',
        photoURL: projectPhotos[4][0],
        photoArray: [...projectPhotos[4]],
        pathURL: '4',
    },
    {
        id: 5,
        title: 'Кухня для молодой семьи в современном стиле.',
        material: 'Фундермакс суперматовый в цвете кашемир. Столешница и стеновая панель Slotex',
        description: '',
        photoURL: projectPhotos[5][0],
        photoArray: [...projectPhotos[5]],
        pathURL: '5',
    },
    {
        id: 6,
        title: 'Мебель в таунхаусе в эркерном варианте',
        material: 'МДФ эмаль',
        description: 'Совместная работа с дизайнером Агеевой Еленой',
        photoURL: projectPhotos[6][0],
        photoArray: [...projectPhotos[6]],
        pathURL: '6',
    },
    {
        id: 7,
        title: 'Мебель в детскую',
        material: 'МДФ эмаль',
        description: 'Совместная работа с дизайнером по интерьеру Агеевой Еленой',
        photoURL: projectPhotos[7][0],
        photoArray: [...projectPhotos[7]],
        pathURL: '7',
    },
    {
        id: 8,
        title: 'Стиль ЛОФТ',
        material: 'МДФ, окутан пленкой ПВХ, ламинат ЕГГЕР',
        description: 'Стоимсть пог. метра от 80000 ₽',
        photoURL: projectPhotos[8][0],
        photoArray: [...projectPhotos[8]],
        pathURL: '8',
    },
    {
        id: 9,
        title: 'Шкаф в детскую',
        material: 'Выполнен из МДФ, покрытой матовой эмалью. Эмаль в двух цветах. Нижние полки из ЛДСП ЕГГЕР',
        description: '',
        photoURL: projectPhotos[9][0],
        photoArray: [...projectPhotos[9]],
        pathURL: '9',
    },
    {
        id: 10,
        title: 'Квартира на Чоботовской',
        material: 'Кухня с антресолями материал Фундермакс, эмаль с 3D фрезеровкой, антресоль с фасадами, с алюминиевой рамкой под золото',
        description: '',
        photoURL: projectPhotos[10][0],
        photoArray: [...projectPhotos[10]],
        pathURL: '10',
    },
    {
        id: 11,
        title: 'Шкаф жк Небо',
        material: 'Фасады МДФ в матовой эмали 3D фрезеровкой',
        description: '',
        photoURL: projectPhotos[11][0],
        photoArray: [...projectPhotos[11]],
        pathURL: '11',
    },
    {
        id: 12,
        title: 'Кухня цвет индиго',
        material: 'Элвик',
        description: '"КАСКАД ПАРК"',
        photoURL: projectPhotos[12][0],
        photoArray: [...projectPhotos[12]],
        pathURL: '12'
    },
    {
        id: 13,
        title: 'Кухня Феникс',
        material: 'Материал Феникс, поталь золото, шпон ореха седого',
        description: '',
        photoURL: projectPhotos[13][0],
        photoArray: [...projectPhotos[13]],
        pathURL: '13'
    },
    {
        id: 14,
        title: 'Проект от дизайнера Агеевой Елены',
        material: 'Материал мателюкс',
        photoURL: projectPhotos[14][0],
        pathURL: '14',
        photoArray: [...projectPhotos[14]],
        description: '',
    },
    {
        id: 15,
        title: 'Кухня Амалия в теплом белом цвете',
        material: 'МДФ с фрезеровкой',
        photoURL: projectPhotos[15][0],
        pathURL: '15',
        photoArray: [...projectPhotos[15]],
        description: '',
    },
    {
        id: 16,
        title: 'Шкаф в прихожей',
        material: 'МДФ',
        photoURL: projectPhotos[16][0],
        pathURL: '16',
        photoArray: [...projectPhotos[16]],
        description: '',
    },
    {
        id: 17,
        title: 'Гардеробная с межкомнатными перегородками',
        material: 'МДФ',
        photoURL: projectPhotos[17][0],
        pathURL: '17',
        photoArray: [...projectPhotos[17]],
        description: 'Система: 4 в 1 ARISTO',
    },
]