import { BackgroundImage, Box, Button, Container, createStyles, Flex, Group, Text } from '@mantine/core'
import { HeaderMegaMenu } from '../components/Header'
import { Footer } from '../components/Footer'
import { IconArrowNarrowLeft } from '@tabler/icons-react'
import { useState } from 'react'
import { TableCataloge, Table2Cataloge, PoofsCataloge, BarnyeCataloge } from '../components/tables/TablesCataloge'
import { UpButton } from '../components/UpButton'

export const TablesPage: React.FC = () => {
    const [table, setTable] = useState(false)
    const [table2, setTable2] = useState(true)
    const [poofs, setPoofs] = useState(false)
    const [tables, setTables] = useState(false)

    const HandleTable = () => {
        setTable(true)
        setTable2(false)
        setPoofs(false)
        setTables(false)
    }
    const HandleTable2 = () => {
        setTable(false)
        setTable2(true)
        setPoofs(false)
        setTables(false)
    }
    const HandlePoofs = () => {
        setTable(false)
        setTable2(false)
        setPoofs(true)
        setTables(false)
    }
    const HandleTables = () => {
        setTable(false)
        setTable2(false)
        setPoofs(false)
        setTables(true)
    }

  return (
    <Box w={'100%'} bg={'#FFF'} h={'max-content'}>
        <HeaderMegaMenu />
        <Container maw={1180}>
            <Group position='apart' mt={'10rem'} className='cataloge-back-group'>
                <Group position='left'>
                    <Box component='a' href='/#cataloge' sx={{textDecoration: 'none'}}>
                        <Flex direction={'row'}>
                            <IconArrowNarrowLeft size={'2.5rem'} color='#10222E' className='cataloge-back-arrow'/>
                            <Text ff={'Roboto'} fz={24} color='#10222E' fw={400} pt={2} ml={'1rem'} className='cataloge-back-text'>Назад</Text>
                        </Flex>
                    </Box>
                </Group>
                <Text ff={'Roboto Condensed'} fw={700} fz={42} color='#10222E' className='cataloge-back-title'>
                    Столы и стулья
                </Text>
            </Group>
            <Group position='apart' mt={'4rem'}>
                <Button onClick={HandleTable} w={280} h={73}
                        bg={table ? '#10222E' : '#FFF'}
                        className={'button-cataloge'}
                        sx={{border: table ? 0 : `2px solid #10222E`}}>
                    <Text ff={'Roboto Condensed'} fz={24} fw={500} lh={'normal'} color={table ? '#FFF' : '#000'}>
                        Столы
                    </Text>
                </Button>
                <Button onClick={HandleTable2} w={280} h={73}
                        bg={table2 ? '#10222E' : '#FFF'}
                        className={'button-cataloge'}
                        sx={{border: table2 ? 0 : `2px solid #10222E`}}>
                    <Text ff={'Roboto Condensed'} fz={24} fw={500} lh={'normal'} color={table2 ? '#FFF' : '#000'}>
                        Стулья
                    </Text>
                </Button>
                <Button onClick={HandlePoofs} w={280} h={73}
                        bg={poofs ? '#10222E' : '#FFF'}
                        className={'button-cataloge'}
                        sx={{border: poofs ? 0 : `2px solid #10222E`}}>
                    <Text ff={'Roboto Condensed'} fz={24} fw={500} lh={'normal'} color={poofs ? '#FFF' : '#000'}>
                        Пуфы и кушетки
                    </Text>
                </Button>
                <Button onClick={HandleTables} w={280} h={73}
                        bg={tables ? '#10222E' : '#FFF'}
                        className={'button-cataloge'}
                        sx={{border: tables ? 0 : `2px solid #10222E`}}>
                    <Text ff={'Roboto Condensed'} fz={24} fw={500} lh={'normal'} color={tables ? '#FFF' : '#000'}>
                        Барные стулья
                    </Text>
                </Button>
            </Group>
            <Group position='left' mt={'4rem'} mb={'4rem'} w={'100%'} ml={'auto'} mr={'auto'} className='group-cataloge'>
                {table ? <TableCataloge /> : null}
                {table2 ? <Table2Cataloge /> : null}
                {poofs ? <PoofsCataloge /> : null}
                {tables ? <BarnyeCataloge /> : null}
            </Group>
        </Container>
        <Footer />
        <UpButton />
    </Box>
  )
}
