// const stoleshnitsa_title = [...Array(2)].map((_,i) => require(`../../image/cataloge/stoleshnitsa/${i}/${0}.jpg`))
// const R104 = [...Array(5)].map((_,i) => require(`../../image/cataloge/stoleshnitsa/avarus/2/R104_${i}.jpg`))
// const R112 = [...Array(5)].map((_,i) => require(`../../image/cataloge/stoleshnitsa/avarus/3/R112_${i}.jpg`))
// const R122 = [...Array(5)].map((_,i) => require(`../../image/cataloge/stoleshnitsa/avarus/4/R122_${i}.jpg`))
// const R310 = [...Array(5)].map((_,i) => require(`../../image/cataloge/stoleshnitsa/avarus/5/R310_${i}.jpg`))
// const R403 = [...Array(5)].map((_,i) => require(`../../image/cataloge/stoleshnitsa/avarus/6/R403_${i}.jpg`))
// const R444 = [...Array(5)].map((_,i) => require(`../../image/cataloge/stoleshnitsa/avarus/7/R444_${i}.jpg`))
// const R500 = [...Array(5)].map((_,i) => require(`../../image/cataloge/stoleshnitsa/avarus/8/R500_${i}.jpg`))
// const R503 = [...Array(5)].map((_,i) => require(`../../image/cataloge/stoleshnitsa/avarus/9/R503_${i}.jpg`))
// const R510 = [...Array(5)].map((_,i) => require(`../../image/cataloge/stoleshnitsa/avarus/10/R510_${i}.jpg`))
// const R511 = [...Array(5)].map((_,i) => require(`../../image/cataloge/stoleshnitsa/avarus/11/R511_${i}.jpg`))
// const R515 = [...Array(5)].map((_,i) => require(`../../image/cataloge/stoleshnitsa/avarus/12/R515_${i}.jpg`))
// const R520 = [...Array(5)].map((_,i) => require(`../../image/cataloge/stoleshnitsa/avarus/13/R520_${i}.jpg`))
// const R522 = [...Array(5)].map((_,i) => require(`../../image/cataloge/stoleshnitsa/avarus/14/R522_${i}.jpg`))
// const R531 = [...Array(5)].map((_,i) => require(`../../image/cataloge/stoleshnitsa/avarus/15/R531_${i}.jpg`))
// const R538 = [...Array(5)].map((_,i) => require(`../../image/cataloge/stoleshnitsa/avarus/16/R538_${i}.jpg`))
// const R541 = [...Array(5)].map((_,i) => require(`../../image/cataloge/stoleshnitsa/avarus/17/R541_${i}.jpg`))
// const R543 = [...Array(5)].map((_,i) => require(`../../image/cataloge/stoleshnitsa/avarus/18/R543_${i}.jpg`))
// const R555 = [...Array(5)].map((_,i) => require(`../../image/cataloge/stoleshnitsa/avarus/19/R555_${i}.jpg`))
// const R558 = [...Array(5)].map((_,i) => require(`../../image/cataloge/stoleshnitsa/avarus/20/R558_${i}.jpg`))
// const R677 = [...Array(5)].map((_,i) => require(`../../image/cataloge/stoleshnitsa/avarus/21/R677_${i}.jpg`))

const AvarusImage = []

for (let i = 0; i < 20; i++) {
    const imageSet = [...Array(5)].map((_, j) => require(`../../image/cataloge/stoleshnitsa/avarus/${i}/${j}.jpg`));
    AvarusImage.push(imageSet);
}

function createImageData(id: number, title: string, imgURL: string, pathURL: string, pathID: string, photoArray: string[], company: string, collection: string, type: string[], sizes: string[]) {
    return {
        id,
        title,
        imgURL,
        pathURL,
        pathID,
        photoArray: [...photoArray],
        company,
        collection,
        type,
        size: sizes.map(size => `${size} мм`)
    };
}

export const AvarusData = [
    createImageData(0, 'Ай Петри', AvarusImage[0][0], 'R444', 'avarus', AvarusImage[0], 'АВАРУС', 'Мраморная коллекция', ['Глянцевая', 'Матовая'], ['3050 x 1440 x 20']),
    createImageData(1, 'Московская ночь', AvarusImage[1][0], 'R677', 'avarus', AvarusImage[1], 'АВАРУС', 'Мраморная коллекция', ['Глянцевая'], ['3200 x 1600 x 20', '3050 x 1440 x 20']),
    createImageData(2, 'Белая Тайга', AvarusImage[2][0], 'R104', 'avarus', AvarusImage[2], 'АВАРУС', 'Моноколор', ['Глянцевая'], ['3200 x 1600 x 20', '3050 x 1440 x 20']),
    createImageData(3, 'Соль Каспия', AvarusImage[3][0], 'R112', 'avarus', AvarusImage[3], 'АВАРУС', 'Гранитная коллекция', ['Глянцевая'], ['3200 x 1600 x 20', '3050 x 1440 x 20']),
    createImageData(4, 'Алмазы Якутии', AvarusImage[4][0], 'R122', 'avarus', AvarusImage[4], 'АВАРУС', 'Гранитная коллекция', ['Глянцевая'], ['3200 x 1600 x 20', '3050 x 1440 x 20']),
    createImageData(5, 'Полярная ночь', AvarusImage[5][0], 'R310', 'avarus', AvarusImage[5], 'АВАРУС', 'Моноколор', ['Глянцевая'], ['3200 x 1600 x 20 мм', '3050 x 1440 x 20 мм']),
    createImageData(6, 'Скалы Карелии', AvarusImage[6][0], 'R403', 'avarus', AvarusImage[6], 'АВАРУС', 'Мраморная коллекция', ['Глянцевая', 'Матовая'], ['3200 x 1600 x 20', '3050 x 1440 x 20']),
    createImageData(7, 'Просторы Поволжья', AvarusImage[7][0], 'R558', 'avarus', AvarusImage[7], 'АВАРУС', 'Мраморная коллекция', ['Глянцевая'], ['3200 x 1600 x 20', '3050 x 1440 x 20']),
    createImageData(8, 'Жемчуг Приморья', AvarusImage[8][0], 'R500', 'avarus', AvarusImage[8], 'АВАРУС', 'Мраморная коллекция', ['Глянцевая', 'Матовая'], ['3050 x 1440 x 20']),
    createImageData(9, 'Уральские Горы', AvarusImage[9][0], 'R503', 'avarus', AvarusImage[9], 'АВАРУС', 'Мраморная коллекция', ['Глянцевая'], ['3200 x 1600 x 20', '3050 x 1440 x 20']),
    createImageData(10, 'Черное Море', AvarusImage[10][0], 'R510', 'avarus', AvarusImage[10], 'АВАРУС', 'Мраморная коллекция', ['Глянцевая', 'Матовая'], ['3200 x 1600 x 20', '3050 x 1440 x 20']),
    createImageData(11, 'Лед Байкала', AvarusImage[11][0], 'R511', 'avarus', AvarusImage[11], 'АВАРУС', 'Мраморная коллекция', ['Глянцевая', 'Матовая'], ['3200 x 1600 x 20', '3050 x 1440 x 20']),
    createImageData(12, 'Вулканы Камчатки', AvarusImage[12][0], 'R515', 'avarus', AvarusImage[12], 'АВАРУС', 'Мраморная коллекция', ['Глянцевая'], ['3200 x 1600 x 20', '3050 x 1440 x 20']),
    createImageData(13, 'Астраханские пески', AvarusImage[13][0], 'R520', 'avarus', AvarusImage[13], 'АВАРУС', 'Мраморная коллекция', ['Глянцевая'], ['3200 x 1600 x 20']),
    createImageData(14, 'Золотое кольцо', AvarusImage[14][0], 'R522', 'avarus', AvarusImage[14], 'АВАРУС', 'Мраморная коллекция', ['Глянцевая'], ['3200 x 1600 x 20', '3050 x 1440 x 20']),
    createImageData(15, 'Донская степь', AvarusImage[15][0], 'R531', 'avarus', AvarusImage[15], 'АВАРУС', 'Мраморная коллекция', ['Глянцевая'], ['3200 x 1600 x 20', '3050 x 1440 x 20']),
    createImageData(16, 'Горы Кавказа', AvarusImage[16][0], 'R538', 'avarus', AvarusImage[16], 'АВАРУС', 'Мраморная коллекция', ['Глянцевая'], ['3200 x 1600 x 20', '3050 x 1440 x 20']),
    createImageData(17, 'Снега Сибири', AvarusImage[17][0], 'R541', 'avarus', AvarusImage[17], 'АВАРУС', 'Мраморная коллекция', ['Глянцевая'], ['3200 x 1600 x 20', '3050 x 1440 x 20']),
    createImageData(18, 'Пик Эльбруса', AvarusImage[18][0], 'R543', 'avarus', AvarusImage[18], 'АВАРУС', 'Мраморная коллекция', ['Глянцевая', 'Матовая'], ['3200 x 1600 x 20', '3050 x 1440 x 20']),
    createImageData(19, 'Балтийский берег', AvarusImage[19][0], 'R555', 'avarus', AvarusImage[19], 'АВАРУС', 'Мраморная коллекция', ['Глянцевая'], ['3050 x 1440 x 20']),
];

const AvantImage = []

for (let i = 0; i < 27; i++) {
    const imageSet = [...Array(5)].map((_, j) => require(`../../image/cataloge/stoleshnitsa/avant/${i}/${j}.jpg`));
    AvantImage.push(imageSet);
}

export const AvantData = [
    createImageData(0, 'Калакатта Мон Сен-Мишель', AvantImage[0][0], '7060', 'avant', AvantImage[0], 'АВАНТ', 'Коллекция Элит', ['Глянцевая'], ['3200 x 1600 x 20', '3050 x 1440 x 20']),
    createImageData(1, 'Авалон', AvantImage[1][0], '1111', 'avant', AvantImage[1], 'АВАНТ', 'Гранитная коллекция', ['Глянцевая'], ['3200 x 1600 x 20', '3050 x 1440 x 20']),
    createImageData(2, 'Грей Тур', AvantImage[2][0], '2060', 'avant', AvantImage[2], 'АВАНТ', 'Мраморная коллекция', ['Глянцевая'], ['3200 x 1600 x 20', '3050 x 1440 x 20']),
    createImageData(3, 'Грей Ним', AvantImage[3][0], '2065', 'avant', AvantImage[3], 'АВАНТ', 'Мраморная коллекция', ['Глянцевая'], ['3200 x 1600 x 20', '3050 x 1440 x 20']),
    createImageData(4, 'Калакатта Амбуаз', AvantImage[4][0], '7020', 'avant', AvantImage[4], 'АВАНТ', 'Коллекция Элит', ['Глянцевая'], ['3200 x 1600 x 20', '3050 x 1440 x 20']),
    createImageData(5, 'Калакатта Шенонсо', AvantImage[5][0], '7030', 'avant', AvantImage[5], 'АВАНТ', 'Коллекция Элит', ['Глянцевая'], ['3200 x 1600 x 20', '3050 x 1440 x 20']),
    createImageData(6, 'Калакатта Бомениль', AvantImage[6][0], '7040', 'avant', AvantImage[6], 'АВАНТ', 'Коллекция Элит', ['Глянцевая'], ['3200 x 1600 x 20', '3050 x 1440 x 20']),
    createImageData(7, 'Калакатта Версаль', AvantImage[7][0], '7070', 'avant', AvantImage[7], 'АВАНТ', 'Коллекция Элит', ['Глянцевая'], ['3200 x 1600 x 20', '3050 x 1440 x 20']),
    createImageData(8, 'Калакатта Пьерфон', AvantImage[8][0], '7080', 'avant', AvantImage[8], 'АВАНТ', 'Коллекция Элит', ['Глянцевая'], ['3200 x 1600 x 20', '3050 x 1440 x 20']),
    createImageData(9, 'Калакатта Рона-Альпы', AvantImage[9][0], '7090', 'avant', AvantImage[9], 'АВАНТ', 'Коллекция Элит', ['Глянцевая'], ['3200 x 1600 x 20', '3050 x 1440 x 20']),
    createImageData(10, 'А+B Калакатта Дофине', AvantImage[10][0], '7400', 'avant', AvantImage[10], 'АВАНТ', 'Коллекция Элит', ['Глянцевая'], ['3200 x 1600 x 20', '3050 x 1440 x 20']),
    createImageData(11, 'Калакатта Венсен', AvantImage[11][0], '7630', 'avant', AvantImage[11], 'АВАНТ', 'Коллекция Элит', ['Глянцевая'], ['3200 x 1600 x 20', '3050 x 1440 x 20']),
    createImageData(12, 'Калакатта Кассель', AvantImage[12][0], '7670', 'avant', AvantImage[12], 'АВАНТ', 'Коллекция Элит', ['Глянцевая'], ['3200 x 1600 x 20', '3050 x 1440 x 20']),
    createImageData(13, 'Калакатта Труа', AvantImage[13][0], '7970', 'avant', AvantImage[13], 'АВАНТ', 'Коллекция Элит', ['Глянцевая'], ['3200 x 1600 x 20', '3050 x 1440 x 20']),
    createImageData(14, 'Ницца Бланк', AvantImage[14][0], '7980', 'avant', AvantImage[14], 'АВАНТ', 'Коллекция Элит', ['Глянцевая'], ['3200 x 1600 x 20', '3050 x 1440 x 20']),
    createImageData(15, 'Сен-Мало', AvantImage[15][0], '9603', 'avant', AvantImage[15], 'АВАНТ', 'Премиальная мраморная коллекция', ['Глянцевая'], ['3200 x 1600 x 20', '3050 x 1440 x 20']),
    createImageData(16, 'Ницца Ноир', AvantImage[16][0], '7985', 'avant', AvantImage[16], 'АВАНТ', 'Коллекция Элит', ['Глянцевая'], ['3200 x 1600 x 20', '3050 x 1440 x 20']),
    createImageData(17, 'Канны Грис', AvantImage[17][0], '7990', 'avant', AvantImage[17], 'АВАНТ', 'Коллекция Элит', ['Глянцевая'], ['3200 x 1600 x 20', '3050 x 1440 x 20']),
    createImageData(18, 'Нант', AvantImage[18][0], '8011', 'avant', AvantImage[18], 'АВАНТ', 'Коллекция Оксид', ['Матовая'], ['3200 x 1600 x 20', '3050 x 1440 x 20']),
    createImageData(19, 'Каркассон', AvantImage[19][0], '8033', 'avant', AvantImage[19], 'АВАНТ', 'Коллекция Оксид', ['Матовая'], ['3200 x 1600 x 20', '3050 x 1440 x 20']),
    createImageData(20, 'Эври', AvantImage[20][0], '8044', 'avant', AvantImage[20], 'АВАНТ', 'Коллекция Оксид', ['Матовая'], ['3200 x 1600 x 20', '3050 x 1440 x 20']),
    createImageData(21, 'Мюроль', AvantImage[21][0], '8300', 'avant', AvantImage[21], 'АВАНТ', 'Коллекция Оксид', ['Матовая'], ['3200 x 1600 x 20', '3050 x 1440 x 20']),
    createImageData(22, 'Кассис', AvantImage[22][0], '8800', 'avant', AvantImage[22], 'АВАНТ', 'Коллекция Оксид', ['Матовая'], ['3200 x 1600 x 20', '3050 x 1440 x 20']),
    createImageData(23, 'Ренн Тэмпл', AvantImage[23][0], '8810', 'avant', AvantImage[23], 'АВАНТ', 'Коллекция Оксид', ['Матовая'], ['3200 x 1600 x 20', '3050 x 1440 x 20']),
    createImageData(24, 'Авиньон Грис', AvantImage[24][0], '9133', 'avant', AvantImage[24], 'АВАНТ', 'Премиальная мраморная коллекция', ['Глянцевая'], ['3200 x 1600 x 20', '3050 x 1440 x 20']),
    createImageData(25, 'Сен-Тропе', AvantImage[25][0], '9212', 'avant', AvantImage[25], 'АВАНТ', 'Премиальная мраморная коллекция', ['Глянцевая'], ['3200 x 1600 x 20', '3050 x 1440 x 20']),
    createImageData(26, 'Ванилла Анси', AvantImage[26][0], '9510', 'avant', AvantImage[26], 'АВАНТ', 'Премиальная мраморная коллекция', ['Глянцевая'], ['3200 x 1600 x 20', '3050 x 1440 x 20']),
]

const GrandexImage = []

for (let i = 0; i < 82; i++) {
    const imageSet = [...Array(5)].map((_, j) => require(`../../image/cataloge/stoleshnitsa/grandex/${i}/${j}.jpg`));
    GrandexImage.push(imageSet);
}

export const GrandexData = [
    createImageData(0, 'Cromium Atom', GrandexImage[0][0], 'A419', 'grandex', GrandexImage[0], 'GRANDEX', 'Adventure (A)', ['Глянцевая'], ['3680 x 760 x 12']),
    createImageData(1, 'Asphalt Material', GrandexImage[1][0], 'A403', 'grandex', GrandexImage[1], 'GRANDEX', 'Adventure (A)', ['Глянцевая'], ['3680 x 760 x 12']),
    createImageData(2, 'Cacao Tree', GrandexImage[2][0], 'A408', 'grandex', GrandexImage[2], 'GRANDEX', 'Adventure (A)', ['Глянцевая'], ['3680 x 760 x 12']),
    createImageData(3, 'Cloudy Mount', GrandexImage[3][0], 'A401', 'grandex', GrandexImage[3], 'GRANDEX', 'Adventure (A)', ['Глянцевая'], ['3680 x 760 x 12']),
    createImageData(4, 'Peach Fruit', GrandexImage[4][0], 'A405', 'grandex', GrandexImage[4], 'GRANDEX', 'Adventure (A)', ['Глянцевая'], ['3680 x 760 x 12']),
    createImageData(5, 'Space Galaxy', GrandexImage[5][0], 'A406', 'grandex', GrandexImage[5], 'GRANDEX', 'Adventure (A)', ['Глянцевая'], ['3680 x 760 x 12']),
    createImageData(6, 'South Coast', GrandexImage[6][0], 'A409', 'grandex', GrandexImage[6], 'GRANDEX', 'Adventure (A)', ['Глянцевая'], ['3680 x 760 x 12']),
    createImageData(7, 'Cotton Wool', GrandexImage[7][0], 'A410', 'grandex', GrandexImage[7], 'GRANDEX', 'Adventure (A)', ['Глянцевая'], ['3680 x 760 x 12']),
    createImageData(8, 'Precious Stone', GrandexImage[8][0], 'A411', 'grandex', GrandexImage[8], 'GRANDEX', 'Adventure (A)', ['Глянцевая'], ['3680 x 760 x 12']),
    createImageData(9, 'Citron Blossom', GrandexImage[9][0], 'A412', 'grandex', GrandexImage[9], 'GRANDEX', 'Adventure (A)', ['Глянцевая'], ['3680 x 760 x 12']),
    createImageData(10, 'Historical Spot', GrandexImage[10][0], 'A414', 'grandex', GrandexImage[10], 'GRANDEX', 'Adventure (A)', ['Глянцевая'], ['3680 x 760 x 12']),
    createImageData(11, 'Endless Desert', GrandexImage[11][0], 'A415', 'grandex', GrandexImage[11], 'GRANDEX', 'Adventure (A)', ['Глянцевая'], ['3680 x 760 x 12']),
    createImageData(12, 'Visible Horizon', GrandexImage[12][0], 'A416', 'grandex', GrandexImage[12], 'GRANDEX', 'Adventure (A)', ['Глянцевая'], ['3680 x 760 x 12']),
    createImageData(13, 'Global Cruise', GrandexImage[13][0], 'A417', 'grandex', GrandexImage[13], 'GRANDEX', 'Adventure (A)', ['Глянцевая'], ['3680 x 760 x 12']),
    createImageData(14, 'Oak Wood', GrandexImage[14][0], 'A418', 'grandex', GrandexImage[14], 'GRANDEX', 'Adventure (A)', ['Глянцевая'], ['3680 x 760 x 12']),
    createImageData(15, 'Coal Mine', GrandexImage[15][0], 'A421', 'grandex', GrandexImage[15], 'GRANDEX', 'Adventure (A)', ['Глянцевая'], ['3680 x 760 x 12']),
    createImageData(16, 'Snow Pile', GrandexImage[16][0], 'A422', 'grandex', GrandexImage[16], 'GRANDEX', 'Adventure (A)', ['Глянцевая'], ['3680 x 760 x 12']),
    createImageData(17, 'Industrial Draft', GrandexImage[17][0], 'A423', 'grandex', GrandexImage[17], 'GRANDEX', 'Adventure (A)', ['Глянцевая'], ['3680 x 760 x 12']),
    createImageData(18, 'Loft Design', GrandexImage[18][0], 'A424', 'grandex', GrandexImage[18], 'GRANDEX', 'Adventure (A)', ['Глянцевая'], ['3680 x 760 x 12']),
    createImageData(19, 'Urban Project', GrandexImage[19][0], 'A425', 'grandex', GrandexImage[19], 'GRANDEX', 'Adventure (A)', ['Глянцевая'], ['3680 x 760 x 12']),
    createImageData(20, 'Onyx', GrandexImage[20][0], 'A426', 'grandex', GrandexImage[20], 'GRANDEX', 'Adventure (A)', ['Глянцевая'], ['3680 x 760 x 12']),
    createImageData(21, 'Primo', GrandexImage[21][0], 'A427', 'grandex', GrandexImage[21], 'GRANDEX', 'Adventure (A)', ['Глянцевая'], ['3680 x 760 x 12']),
    createImageData(22, 'Andes', GrandexImage[22][0], 'A428', 'grandex', GrandexImage[22], 'GRANDEX', 'Adventure (A)', ['Глянцевая'], ['3680 x 760 x 12']),
    createImageData(23, 'Conrete Quartz', GrandexImage[23][0], 'A429', 'grandex', GrandexImage[23], 'GRANDEX', 'Adventure (A)', ['Глянцевая'], ['3680 x 760 x 12']),
    createImageData(24, 'Arezzol', GrandexImage[24][0], 'C801', 'grandex', GrandexImage[24], 'GRANDEX', 'Adventure (A)', ['Глянцевая'], ['3680 x 760 x 12']),
    createImageData(25, 'Placenzal', GrandexImage[25][0], 'C802', 'grandex', GrandexImage[25], 'GRANDEX', 'Adventure (A)', ['Глянцевая'], ['3680 x 760 x 12']),
    createImageData(26, 'Salernol', GrandexImage[26][0], 'C803', 'grandex', GrandexImage[26], 'GRANDEX', 'Adventure (A)', ['Глянцевая'], ['3680 x 760 x 12']),
    createImageData(27, 'Poppy Seed', GrandexImage[27][0], 'D301', 'grandex', GrandexImage[27], 'GRANDEX', 'Adventure (A)', ['Глянцевая'], ['3680 x 760 x 12']),
    createImageData(28, 'Morning Coffee', GrandexImage[28][0], 'D302', 'grandex', GrandexImage[28], 'GRANDEX', 'Adventure (A)', ['Глянцевая'], ['3680 x 760 x 12']),
    createImageData(29, 'Raisined Chocolate', GrandexImage[29][0], 'D303', 'grandex', GrandexImage[29], 'GRANDEX', 'Adventure (A)', ['Глянцевая'], ['3680 x 760 x 12']),
    createImageData(30, 'Ice Cream', GrandexImage[30][0], 'D304', 'grandex', GrandexImage[30], 'GRANDEX', 'Adventure (A)', ['Глянцевая'], ['3680 x 760 x 12']),
    createImageData(31, 'Melted Butter', GrandexImage[31][0], 'D305', 'grandex', GrandexImage[31], 'GRANDEX', 'Adventure (A)', ['Глянцевая'], ['3680 x 760 x 12']),
    createImageData(32, 'Aspen Pie', GrandexImage[32][0], 'D307', 'grandex', GrandexImage[32], 'GRANDEX', 'Adventure (A)', ['Глянцевая'], ['3680 x 760 x 12']),
    createImageData(33, 'Cubic Mint', GrandexImage[33][0], 'D308', 'grandex', GrandexImage[33], 'GRANDEX', 'Adventure (A)', ['Глянцевая'], ['3680 x 760 x 12']),
    createImageData(34, 'Mushroom Soup', GrandexImage[34][0], 'D309', 'grandex', GrandexImage[34], 'GRANDEX', 'Adventure (A)', ['Глянцевая'], ['3680 x 760 x 12']),
    createImageData(35, 'Herbal Ash', GrandexImage[35][0], 'D310', 'grandex', GrandexImage[35], 'GRANDEX', 'Adventure (A)', ['Глянцевая'], ['3680 x 760 x 12']),
    createImageData(36, 'Pietra Absorb', GrandexImage[36][0], 'D312', 'grandex', GrandexImage[36], 'GRANDEX', 'Adventure (A)', ['Глянцевая'], ['3680 x 760 x 12']),
    createImageData(37, 'Milky Way', GrandexImage[37][0], 'D313', 'grandex', GrandexImage[37], 'GRANDEX', 'Adventure (A)', ['Глянцевая'], ['3680 x 760 x 12']),
    createImageData(38, 'Arctic Ice', GrandexImage[38][0], 'D314', 'grandex', GrandexImage[38], 'GRANDEX', 'Adventure (A)', ['Глянцевая'], ['3680 x 760 x 12']),
    createImageData(39, 'Spacemen Food', GrandexImage[39][0], 'D315', 'grandex', GrandexImage[39], 'GRANDEX', 'Adventure (A)', ['Глянцевая'], ['3680 x 760 x 12']),
    createImageData(40, 'Velvet Bean', GrandexImage[40][0], 'D318', 'grandex', GrandexImage[40], 'GRANDEX', 'Adventure (A)', ['Глянцевая'], ['3680 x 760 x 12']),
    createImageData(41, 'Soil', GrandexImage[41][0], 'D320', 'grandex', GrandexImage[41], 'GRANDEX', 'Adventure (A)', ['Глянцевая'], ['3680 x 760 x 12']),
    createImageData(42, 'Snowy Moscow', GrandexImage[42][0], 'E603', 'grandex', GrandexImage[42], 'GRANDEX', 'Adventure (A)', ['Глянцевая'], ['3680 x 760 x 12']),
    createImageData(43, 'Indian Mantra', GrandexImage[43][0], 'E605', 'grandex', GrandexImage[43], 'GRANDEX', 'Adventure (A)', ['Глянцевая'], ['3680 x 760 x 12']),
    createImageData(44, 'Business Tokyo', GrandexImage[44][0], 'E609', 'grandex', GrandexImage[44], 'GRANDEX', 'Adventure (A)', ['Глянцевая'], ['3680 x 760 x 12']),
    createImageData(45, 'English Tea', GrandexImage[45][0], 'E613', 'grandex', GrandexImage[45], 'GRANDEX', 'Adventure (A)', ['Глянцевая'], ['3680 x 760 x 12']),
    createImageData(46, 'Marrakesh Plaza', GrandexImage[46][0], 'E614', 'grandex', GrandexImage[46], 'GRANDEX', 'Adventure (A)', ['Глянцевая'], ['3680 x 760 x 12']),
    createImageData(47, 'Saturn Ring', GrandexImage[47][0], 'E618', 'grandex', GrandexImage[47], 'GRANDEX', 'Adventure (A)', ['Глянцевая'], ['3680 x 760 x 12']),
    createImageData(48, 'Cut Diamond', GrandexImage[48][0], 'J504', 'grandex', GrandexImage[48], 'GRANDEX', 'Adventure (A)', ['Глянцевая'], ['3680 x 760 x 12']),
    createImageData(49, 'Pearl Neckplace', GrandexImage[49][0], 'J505', 'grandex', GrandexImage[49], 'GRANDEX', 'Adventure (A)', ['Глянцевая'], ['3680 x 760 x 12']),
    createImageData(50, 'American Obsidian', GrandexImage[50][0], 'J509', 'grandex', GrandexImage[50], 'GRANDEX', 'Adventure (A)', ['Глянцевая'], ['3680 x 760 x 12']),
    createImageData(51, 'Terazzo Bianco', GrandexImage[51][0], 'J510', 'grandex', GrandexImage[51], 'GRANDEX', 'Adventure (A)', ['Глянцевая'], ['3680 x 760 x 12']),
    createImageData(52, 'Hazel Flow A', GrandexImage[52][0], 'M701', 'grandex', GrandexImage[52], 'GRANDEX', 'Adventure (A)', ['Глянцевая'], ['3680 x 760 x 12']),
    createImageData(53, 'Water Weed A', GrandexImage[53][0], 'M703', 'grandex', GrandexImage[53], 'GRANDEX', 'Adventure (A)', ['Глянцевая'], ['3680 x 760 x 12']),
    createImageData(54, 'Water Weed B', GrandexImage[54][0], 'M703', 'grandex', GrandexImage[54], 'GRANDEX', 'Adventure (A)', ['Глянцевая'], ['3680 x 760 x 12']),
    createImageData(55, 'Shell Surface A', GrandexImage[55][0], 'M704', 'grandex', GrandexImage[55], 'GRANDEX', 'Adventure (A)', ['Глянцевая'], ['3680 x 760 x 12']),
    createImageData(56, 'Shell Surface B', GrandexImage[56][0], 'M704', 'grandex', GrandexImage[56], 'GRANDEX', 'Adventure (A)', ['Глянцевая'], ['3680 x 760 x 12']),
    createImageData(57, 'Stormy Sea A', GrandexImage[57][0], 'M706', 'grandex', GrandexImage[57], 'GRANDEX', 'Adventure (A)', ['Глянцевая'], ['3680 x 760 x 12']),
    createImageData(58, 'Stormy Sea B', GrandexImage[58][0], 'M706', 'grandex', GrandexImage[58], 'GRANDEX', 'Adventure (A)', ['Глянцевая'], ['3680 x 760 x 12']),
    createImageData(59, 'Deep Water A', GrandexImage[59][0], 'M708', 'grandex', GrandexImage[59], 'GRANDEX', 'Adventure (A)', ['Глянцевая'], ['3680 x 760 x 12']),
    createImageData(60, 'Deep Water B', GrandexImage[60][0], 'M708', 'grandex', GrandexImage[60], 'GRANDEX', 'Adventure (A)', ['Глянцевая'], ['3680 x 760 x 12']),
    createImageData(61, 'Float Rock A', GrandexImage[61][0], 'M710', 'grandex', GrandexImage[61], 'GRANDEX', 'Adventure (A)', ['Глянцевая'], ['3680 x 760 x 12']),
    createImageData(62, 'Float Rock B', GrandexImage[62][0], 'M710', 'grandex', GrandexImage[62], 'GRANDEX', 'Adventure (A)', ['Глянцевая'], ['3680 x 760 x 12']),
    createImageData(63, 'Sparkling Wave A', GrandexImage[63][0], 'M711', 'grandex', GrandexImage[63], 'GRANDEX', 'Adventure (A)', ['Глянцевая'], ['3680 x 760 x 12']),
    createImageData(64, 'Sparkling Wave B', GrandexImage[64][0], 'M711', 'grandex', GrandexImage[64], 'GRANDEX', 'Adventure (A)', ['Глянцевая'], ['3680 x 760 x 12']),
    createImageData(65, 'Stylish Moon A', GrandexImage[65][0], 'M712', 'grandex', GrandexImage[65], 'GRANDEX', 'Adventure (A)', ['Глянцевая'], ['3680 x 760 x 12']),
    createImageData(66, 'Stylish Moon B', GrandexImage[66][0], 'M712', 'grandex', GrandexImage[66], 'GRANDEX', 'Adventure (A)', ['Глянцевая'], ['3680 x 760 x 12']),
    createImageData(67, 'Whitesand Beach A', GrandexImage[67][0], 'M713', 'grandex', GrandexImage[67], 'GRANDEX', 'Adventure (A)', ['Глянцевая'], ['3680 x 760 x 12']),
    createImageData(68, 'Whitesand Beach B', GrandexImage[68][0], 'M713', 'grandex', GrandexImage[68], 'GRANDEX', 'Adventure (A)', ['Глянцевая'], ['3680 x 760 x 12']),
    createImageData(69, 'Beton Bridge A', GrandexImage[69][0], 'M715', 'grandex', GrandexImage[69], 'GRANDEX', 'Adventure (A)', ['Глянцевая'], ['3680 x 760 x 12']),
    createImageData(70, 'Beton Bridge B', GrandexImage[70][0], 'M715', 'grandex', GrandexImage[70], 'GRANDEX', 'Adventure (A)', ['Глянцевая'], ['3680 x 760 x 12']),
    createImageData(71, 'Neptun Trident A', GrandexImage[71][0], 'M718', 'grandex', GrandexImage[71], 'GRANDEX', 'Adventure (A)', ['Глянцевая'], ['3680 x 760 x 12']),
    createImageData(72, 'Neptun Trident B', GrandexImage[72][0], 'M718', 'grandex', GrandexImage[72], 'GRANDEX', 'Adventure (A)', ['Глянцевая'], ['3680 x 760 x 12']),
    createImageData(73, 'Octopus Ink A', GrandexImage[73][0], 'M719', 'grandex', GrandexImage[73], 'GRANDEX', 'Adventure (A)', ['Глянцевая'], ['3680 x 760 x 12']),
    createImageData(74, 'Octopus Ink B', GrandexImage[74][0], 'M719', 'grandex', GrandexImage[74], 'GRANDEX', 'Adventure (A)', ['Глянцевая'], ['3680 x 760 x 12']),
    createImageData(75, 'Carrara Lunar A', GrandexImage[75][0], 'M720', 'grandex', GrandexImage[75], 'GRANDEX', 'Adventure (A)', ['Глянцевая'], ['3680 x 760 x 12']),
    createImageData(76, 'Timber Wolf A', GrandexImage[76][0], 'M723', 'grandex', GrandexImage[76], 'GRANDEX', 'Adventure (A)', ['Глянцевая'], ['3680 x 760 x 12']),
    createImageData(77, 'Venice A', GrandexImage[77][0], 'M727', 'grandex', GrandexImage[77], 'GRANDEX', 'Adventure (A)', ['Глянцевая'], ['3680 x 760 x 12']),
    createImageData(78, 'Venice B', GrandexImage[78][0], 'M727', 'grandex', GrandexImage[78], 'GRANDEX', 'Adventure (A)', ['Глянцевая'], ['3680 x 760 x 12']),
    createImageData(79, 'Moon Coast A', GrandexImage[79][0], 'M731', 'grandex', GrandexImage[79], 'GRANDEX', 'Adventure (A)', ['Глянцевая'], ['3680 x 760 x 12']),
    createImageData(80, 'Marble Mirage A', GrandexImage[80][0], 'M732', 'grandex', GrandexImage[80], 'GRANDEX', 'Adventure (A)', ['Глянцевая'], ['3680 x 760 x 12']),
    createImageData(81, 'Stellar Depth A', GrandexImage[81][0], 'M733', 'grandex', GrandexImage[81], 'GRANDEX', 'Adventure (A)', ['Глянцевая'], ['3680 x 760 x 12']),
]