import React, { useState } from 'react'
import { Navbar } from '../Header'
import { BackgroundImage, Box, Button, Container, Flex, Group, Text, } from '@mantine/core'
import { changeColorButton } from '../../styles/style'
import { dataHeader } from '../../content/data'
import { IconSquareX } from '@tabler/icons-react'
import { ModalForm } from '../ModalForm'
import { UpButton } from '../UpButton'

interface contentData {
  id: number,
  title: string,
}

const ContentProps: React.FC<contentData> = ({id, title}) => {
  return (
    <Text maw={353} fz={18} ff={'Roboto'} fw={400} color='#FFF' mt={'1rem'} lh={'normal'} key={id}>
      {title}
    </Text>
  )
}

const ContainerProps: React.FC = () => {
  return (
    <>
    {dataHeader.map((item) => (
      <ContentProps key={item.id} id={item.id} title={item.title} />
    ))}
    </>
  )
}

export const SectionOne: React.FC = (type: any) => {
    const Background = require('../../image/Background.jpg')
    const [modal, setModal] = useState(false)

  return (
    <>
    {modal &&
        <Box w={'100vw'} h={'100vh'} bg={`rgba(0, 0, 0, 0.6)`} sx={{position: 'fixed', zIndex: 10001, top: 0,}}>
          <Button variant='transparent' p={0} m={'1rem'} onClick={() => setModal(false)} sx={{position: 'absolute', top: 0, right: 0, zIndex: 10002}}>
            <IconSquareX size={32} color='#FFF'/>
          </Button>
            <ModalForm />
        </Box>
      }
    <Box w={'100%'} h={'max-content'} pt={'6rem'} className='sec-1-cataloge'>
        <Navbar />
        <UpButton />
        <BackgroundImage src={Background} w={'100%'} h={'max-content'} className='sec-1-background'>
          <Box bg={'rgba(0, 0, 0, 0.35)'} h={'100%'}>
            <Container maw={1180}>
              <Text fz={'4rem'} color='#FFF' ff={'Roboto Condensed'} lh={'normal'} maw={853} pt={'9rem'} fw={500} id='#' className='sec-1-title'>
                  Мебель  с вашим характером от студии Amalya Home
              </Text>
              <Group h={'100%'} mt={'2rem'} position='apart' pb={155} className='sec-1-group'>
                  <Box className='sec-1-box' h={'100%'} sx={{borderLeftWidth:16, borderLeftColor: '#6C725A', borderLeftStyle:'solid',}}>
                    <Flex direction={'column'} sx={{border: 16, borderColor: '#6C725A'}} ml={'2rem'}>
                      <ContainerProps />
                        <Button onClick={() => setModal(true)} maw={332} h={55} bg={'#6C725A'} mt={'2rem'} mb={'1rem'} sx={{
                          '&:hover': {
                            animation: `${changeColorButton} 500ms`,
                            backgroundColor: '#73303F'
                          }
                        }}>
                          <Text ff={'Roboto Condensed'} color='#FFF' fw={500} lh={'normal'} lts={2}>
                            Обратная связь
                          </Text>
                        </Button>
                    </Flex>
                  </Box>
              </Group>
            </Container>
          </Box>
        </BackgroundImage>
    </Box>
    </>
  )
}
