const classic = [...Array(5)].map((_, i) => require(`../../image/cataloge/kitchen/classic/${i}.jpg`))
const loft = [...Array(3)].map((_,i) => require(`../../image/cataloge/kitchen/loft/${i}.jpg`))
const modern = [...Array(4)].map((_, i) => require(`../../image/cataloge/kitchen/modern/${i}.jpg`))
const neoclassic = [...Array(8)].map((_, i) => require(`../../image/cataloge/kitchen/neoclassic/${i}.jpg`))

export const ClassicKitchenData = [
    {
        id: 0,
        title: 'Комо',
        imgURL: classic[0]
    },
    {
        id: 1,
        title: 'Инфинити',
        imgURL: classic[1]
    },
    {
        id: 2,
        title: 'Нике',
        imgURL: classic[2]
    },
    {
        id: 3,
        title: 'Нике Аворио',
        imgURL: classic[3]
    },
    {
        id: 4,
        title: 'Венето Классико',
        imgURL: classic[4]
    },
]

export const LoftKitchenData = [
    {
        id: 0,
        title: 'Кухня',
        imgURL: loft[0]
    },
    {
        id: 1,
        title: 'Кухня',
        imgURL: loft[1]
    },
    {
        id: 2,
        title: 'Кухня',
        imgURL: loft[2]
    },
]

export const ModernKitchenData = [
    {
        id: 0,
        title: 'Кухня "Амалия" выполнена в белоснежной эмали с тонкой фрезеровкой по периметру фасада.',
        imgURL: modern[0]
    },
    {
        id: 1,
        title: 'Кухня',
        imgURL: modern[1]
    },
    {
        id: 2,
        title: 'Кухня',
        imgURL: modern[2]
    },
    {
        id: 3,
        title: 'Кухня',
        imgURL: modern[3]
    },
]

export const NeoClassicKitchenData = [
    {
        id: 0,
        title: 'Брэра',
        imgURL: neoclassic[0]
    },
    {
        id: 1,
        title: 'Венето Бьянко',
        imgURL: neoclassic[1]
    },
    {
        id: 2,
        title: 'Венето Кадоро',
        imgURL: neoclassic[2]
    },
    {
        id: 3,
        title: 'Венето Ровере',
        imgURL: neoclassic[3]
    },
    {
        id: 4,
        title: 'Милан Бьянко',
        imgURL: neoclassic[4]
    },
    {
        id: 5,
        title: 'Милан Гриджио',
        imgURL: neoclassic[5]
    },
    {
        id: 6,
        title: 'Милан Кашемир',
        imgURL: neoclassic[6]
    },
    {
        id: 7,
        title: 'Кухня Милан. Массив ясеня',
        imgURL: neoclassic[7]
    },
]