import { BackgroundImage, Box, Container, Flex, Group, Grid, Image, Text } from "@mantine/core"
import { IconArrowNarrowLeft } from "@tabler/icons-react"
import {useParams} from "react-router-dom"
import { HeaderMegaMenu } from "../components/Header"
import { Footer } from "../components/Footer"
import { dataSectionFive } from "../content/data"

export const ProjectDetails: React.FC = () => {
    const { pathURL } = useParams()
    
    return (
        <Box w={'100%'} bg={'#FFF'} h={'max-content'}>
            <HeaderMegaMenu />
            <Container maw={1180} pb={'4rem'} h={'max-content'}>
                <Group position='apart' mt={'8rem'} className='cataloge-back-group'>
                        <Box component='a' href='/#portfolio' sx={{textDecoration: 'none'}}>
                            <Flex direction={'row'}>
                                <IconArrowNarrowLeft size={'2.5rem'} color='#10222E' className='cataloge-back-arrow'/>
                                <Text ff={'Roboto'} fz={24} color='#10222E' fw={400} ml={'1rem'} pt={2} className='cataloge-back-text'>Назад</Text>
                            </Flex>
                        </Box>
                </Group>
                {dataSectionFive
                    .filter((item) => item.pathURL == pathURL)
                    .map((item, index) => (
                        <Box key={index} mt={'4rem'}>
                            <Text key={index} ff={'Roboto Condensed'} mb={'2rem'} fw={500} fz={24} color='#10222E'>
                                {item.title}
                            </Text>
                            <Grid grow gutter="xs">
                                {item.photoArray.map((item, index) => (
                                    <Grid.Col lg={4} md={6} sm={12} key={index}>
                                        <Image src={item}/>
                                    </Grid.Col>
                                ))}
                            </Grid>
                        </Box>
                    ))
                }
            </Container>
            <Footer />
        </Box>
    )
}