import React from 'react'
import { Box, Flex } from '@mantine/core'

const colors = [
    {id: 0, title: '#5D5250'},
    {id: 1, title: '#949E94'},
    {id: 2, title: '#6C725A'},
    {id: 3, title: '#536223'},
]

interface ComponentProps {
    id: number,
    title: string,
}

const PropsContainer: React.FC<ComponentProps> = ({id, title}) => {
  return <Box w={'1rem'} h={'1rem'} mr={'0.5rem'} bg={title} key={id}></Box>
}

const Container: React.FC = () => {
  return (
    <>
    {colors.map((item) => (
      <PropsContainer key={item.id} id={item.id} title={item.title}/>
    ))}
    </>
  )
}

export function BlocksShort() {
  return (
    <Flex mt={'2rem'}>
        <Container />
    </Flex>
  )
}
