const photos = [...Array(11)].map((_, i) => require(`../../image/cataloge/shkaf/${i}.jpg`))

export const ShkafData = [
    {
        id: 0,
        title: 'Шкаф',
        imgURL: photos[0]
    },
    {
        id: 1,
        title: 'Шкаф',
        imgURL: photos[1]
    },
    {
        id: 2,
        title: 'Шкафы в прихожую. Матовая эмаль',
        imgURL: photos[2]
    },
    {
        id: 3,
        title: 'Фасады Элвик люкс',
        imgURL: photos[3]
    },
    {
        id: 4,
        title: 'Шкафы в прихожую. Матовая эмаль',
        imgURL: photos[4]
    },
    {
        id: 5,
        title: 'Шкаф в комнату для  подростка',
        imgURL: photos[5]
    },
    {
        id: 6,
        title: 'Шкафы в детскую для девочки. Эмаль матовая, эмаль с 3D фрезеровкой',
        imgURL: photos[6]
    },
    {
        id: 7,
        title: 'Шкаф эмаль с фрезеровкой в дом из бруса',
        imgURL: photos[7]
    },
    {
        id: 8,
        title: 'Шкаф эмаль с 3D фрезеровкой "Ёлочка"',
        imgURL: photos[8]
    },
    {
        id: 9,
        title: 'Шкаф жк Небо. Фасады МДФ в матовой эмали 3D фрезеровкой',
        imgURL: photos[9]
    },
]