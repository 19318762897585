import React from "react"
import { BackgroundImage, Box, Text } from "@mantine/core"
import { TableData, Table2Data, BarnyeData, PoofsData } from "./TablesData"

interface ComponentProps {
    id: number,
    title: string,
    imgURL: any
}

const PropsContainer: React.FC<ComponentProps> = ({id, title, imgURL}) => {
    return (
        <Box key={id} w={380} h={'max-content'}>
            <BackgroundImage w={'100%'} h={500} src={imgURL}/>
            <Box w={'100%'} h={100} bg={'#10222E'}>
                <Text ff={'Roboto Condensed'} color="#FFF" fz={20} lh={'normal'} p={'1rem'}>
                    {title}
                </Text>
            </Box>
        </Box>
    )
}

export const TableCataloge: React.FC = () => {
    return (
        <>
        {TableData.map((item) => (
            <PropsContainer key={item.id} id={item.id} title={item.title} imgURL={item.imgURL}/>
        ))}
        </>
    )
}
export const Table2Cataloge: React.FC = () => {
    return (
        <>
        {Table2Data.map((item) => (
            <PropsContainer key={item.id} id={item.id} title={item.title} imgURL={item.imgURL}/>
        ))}
        </>
    )
}
export const BarnyeCataloge: React.FC = () => {
    return (
        <>
        {BarnyeData.map((item) => (
            <PropsContainer key={item.id} id={item.id} title={item.title} imgURL={item.imgURL}/>
        ))}
        </>
    )
}
export const PoofsCataloge: React.FC = () => {
    return (
        <>
        {PoofsData.map((item) => (
            <PropsContainer key={item.id} id={item.id} title={item.title} imgURL={item.imgURL}/>
        ))}
        </>
    )
}