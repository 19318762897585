const photos = [...Array(6)].map((_,i) => require(`../../image/cataloge/gostinaya/${i}.jpg`))

export const GostinayaData = [
    {
        id: 0,
        title: 'Гостиная',
        imgURL: photos[0]
    },
    {
        id: 1,
        title: 'Гостиная',
        imgURL: photos[1]
    },
    {
        id: 2,
        title: 'Гостиная',
        imgURL: photos[2]
    },
    {
        id: 3,
        title: 'Гостиная',
        imgURL: photos[3]
    },
    {
        id: 4,
        title: 'Гостиная',
        imgURL: photos[4]
    },
    {
        id: 5,
        title: 'Гостиная',
        imgURL: photos[5]
    }
]