import { BackgroundImage, Box, Button, Container, createStyles, Flex, Group, Text } from '@mantine/core'
import { HeaderMegaMenu } from '../components/Header'
import { Footer } from '../components/Footer'
import { IconArrowNarrowLeft } from '@tabler/icons-react'
import { useState } from 'react'
import { ClassicContainer, ModernContainer, LoftContainer, NeoClassicContainer } from '../components/kitchen/KithenCataloge'
import { UpButton } from '../components/UpButton'



export const KitchenPage: React.FC = () => {
    const [modern, setModern] = useState(true)
    const [classic, setClassic] = useState(false)
    const [loft, setLoft] = useState(false)
    const [neoClassic, setNeoClassic] = useState(false)

    const HandleModern = () => {
        setModern(true)
        setClassic(false)
        setLoft(false)
        setNeoClassic(false)
    }
    const HandleClassic = () => {
        setModern(false)
        setClassic(true)
        setLoft(false)
        setNeoClassic(false)
    }
    const HandleLoft = () => {
        setModern(false)
        setClassic(false)
        setLoft(true)
        setNeoClassic(false)
    }
    const HandleNeoClassic = () => {
        setModern(false)
        setClassic(false)
        setLoft(false)
        setNeoClassic(true)
    }

  return (
    <Box w={'100%'} bg={'#FFF'} h={'max-content'}>
        <HeaderMegaMenu />
        <Container maw={1180}>
            <Group position='apart' mt={'10rem'} className='cataloge-back-group'>
                <Group position='left'>
                    <Box component='a' href='/#cataloge' sx={{textDecoration: 'none'}}>
                        <Flex direction={'row'}>
                            <IconArrowNarrowLeft size={'2.5rem'} color='#10222E' className='cataloge-back-arrow'/>
                            <Text ff={'Roboto'} fz={24} color='#10222E' fw={400} pt={2} ml={'1rem'} className='cataloge-back-text'>Назад</Text>
                        </Flex>
                    </Box>
                </Group>
                <Text ff={'Roboto Condensed'} fw={700} fz={42} color='#10222E' className='cataloge-back-title'>
                    Кухни
                </Text>
            </Group>
            <Group position='apart' mt={'4rem'} className='button-cataloge-group'>
                <Button onClick={HandleModern} w={280} h={73}
                        bg={modern ? '#10222E' : '#FFF'}
                        className={'button-cataloge'}
                        sx={{border: modern ? 0 : `2px solid #10222E`}}>
                    <Text ff={'Roboto Condensed'} fz={24} fw={500} lh={'normal'} color={modern ? '#FFF' : '#000'}>
                        Модерн
                    </Text>
                </Button>
                <Button onClick={HandleClassic} w={280} h={73}
                        bg={classic ? '#10222E' : '#FFF'}
                        className={'button-cataloge'}
                        sx={{border: classic ? 0 : `2px solid #10222E`}}>
                    <Text ff={'Roboto Condensed'} fz={24} fw={500} lh={'normal'} color={classic ? '#FFF' : '#000'}>
                        Классика
                    </Text>
                </Button>
                <Button onClick={HandleLoft} w={280} h={73}
                        bg={loft ? '#10222E' : '#FFF'}
                        className={'button-cataloge'}
                        sx={{border: loft ? 0 : `2px solid #10222E`}}>
                    <Text ff={'Roboto Condensed'} fz={24} fw={500} lh={'normal'} color={loft ? '#FFF' : '#000'}>
                        Лофт
                    </Text>
                </Button>
                <Button onClick={HandleNeoClassic} w={280} h={73}
                        bg={neoClassic ? '#10222E' : '#FFF'}
                        className={'button-cataloge'}
                        sx={{border: neoClassic ? 0 : `2px solid #10222E`}}>
                    <Text ff={'Roboto Condensed'} fz={24} fw={500} lh={'normal'} color={neoClassic ? '#FFF' : '#000'}>
                        Неоклассика
                    </Text>
                </Button>
            </Group>
            <Group position='left' mt={'4rem'} mb={'4rem'} w={'100%'} ml={'auto'} mr={'auto'} className='group-cataloge'>
                {classic ? <ClassicContainer /> : null}
                {modern ? <ModernContainer /> : null}
                {loft ? <LoftContainer /> : null}
                {neoClassic ? <NeoClassicContainer /> : null}
            </Group>
        </Container>
        <Footer />
        <UpButton />
    </Box>
  )
}
