import React from "react"
import { BackgroundImage, Box, Text } from "@mantine/core"
import { ClassicKitchenData, LoftKitchenData, ModernKitchenData, NeoClassicKitchenData } from "./KitchenData"

interface ComponentProps {
    id: number,
    title: string,
    imgURL: any
}

const PropsContainer: React.FC<ComponentProps> = ({id, title, imgURL}) => {
    return (
        <Box key={id} w={380} h={'max-content'} className="cataloge-component">
            <BackgroundImage w={'100%'} h={500} src={imgURL}/>
            <Box w={'100%'} h={100} bg={'#10222E'}>
                <Text ff={'Roboto Condensed'} color="#FFF" fz={20} lh={'normal'} p={'1rem'}>
                    {title}
                </Text>
            </Box>
        </Box>
    )
}

export const ClassicContainer: React.FC = () => {
    return (
        <>
        {ClassicKitchenData.map((item) => (
            <PropsContainer key={item.id} id={item.id} title={item.title} imgURL={item.imgURL}/>
        ))}
        </>
    )
}

export const LoftContainer: React.FC = () => {
    return (
        <>
        {LoftKitchenData.map((item) => (
            <PropsContainer key={item.id} id={item.id} title={item.title} imgURL={item.imgURL}/>
        ))}
        </>
    )
}

export const ModernContainer: React.FC = () => {
    return (
        <>
        {ModernKitchenData.map((item) => (
            <PropsContainer key={item.id} id={item.id} title={item.title} imgURL={item.imgURL}/>
        ))}
        </>
    )
}

export const NeoClassicContainer: React.FC = () => {
    return (
        <>
        {NeoClassicKitchenData.map((item) => (
            <PropsContainer key={item.id} id={item.id} title={item.title} imgURL={item.imgURL}/>
        ))}
        </>
    )
}