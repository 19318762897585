import { BackgroundImage, Box, Button, Center, Container, Divider, Flex, Group, Text } from '@mantine/core'
import { HeaderMegaMenu } from '../components/Header'
import { Footer } from '../components/Footer'
import { IconArrowNarrowLeft } from '@tabler/icons-react'
import '../components/Stoleshnitsa/Stoleshnitsa.css'
import { UpButton } from '../components/UpButton'
import { motion } from 'framer-motion'
import { useLocation } from 'react-router-dom'
import { useEffect } from 'react'

const avarus = require('../image/cataloge/stoleshnitsa/avarus/15/3.jpg')
const grandex = require('../image/cataloge/stoleshnitsa/grandex/17/2.jpg')
const avant = require('../image/cataloge/stoleshnitsa/avant/19/3.jpg')

const Quartz = [...Array(3)].map((_, i) => require(`../image/quartz/quartz_${i}.jpg`))

const data = [
  {
    title: 'Аварус',
    stone: 'Кварцевый камень',
    country: 'Россия',
    description: 'Новая торговая марка АВАРУС (AVARUS) была создана компанией INTERSTONE специально для отечественного рынка. Под этим брендом выпускается кварцевый агломерат на собственном заводе компании в РФ, что позволяет контролировать создание плит на каждом этапе производства',
    imgURL: Quartz[0],
    image: avarus,
    id: 'avarus',
  },
  // {
  //   title: 'Caesarstone',
  //   stone: 'Кварцевый камень',
  //   country: 'Израиль',
  //   description: 'Caesarstone — кварцевый камень официально считается самым востребованным из материалов премиум-класса. В палитре Caesarstone собраны самые лучшие, самые востребованные цвета. Из кварцевого агломерата Caesarstone можно создавать бесшовные и сложные пластичные формы.Узкая специализация, инновационные технологии и оборудование последнего поколения позволили создать материал, который обоснованно занял свое место в рядах лучших мировых брендов кварцевого камня.',
  //   imgURL: Quartz[1],
  // },
  // {
  //   title: 'Noblle Quartz',
  //   stone: 'Кварцевый камень',
  //   country: 'Китай',
  //   description: 'Noble Quartz - является новинкой в мире кварцевого агломерата. На данный момент коллекция насчитывает 6 популярных классических оттенков. Завод по изготовлению плит Нобл Кварца находится на территории Китая и имеет современное оборудование. Продукция соответствует всем необходимым стандартам качества, а оптимизированная современная технология производства позволяет получить необходимое качество по рекордно низкой цене',
  //   imgURL: Quartz[2],
  // },
  {
    title: 'Avant Quartz',
    stone: 'Кварцевый камень',
    country: 'КНР',
    description: 'Avant Quartz (Авант Кварц) — это кварцевый камень, созданный с помощью современных технологий. Взяв лучшее от природы и человеческого мастерства, кварцевый агломерат представляет собой экологически чистый материал, который состоит из натуральной кварцевый крошки (95%), полимерных смол (4%) и красящего пигмента (1%)',
    imgURL: Quartz[1],
    image: avant,
    id: 'avant',
  },
]

const data2 = [
  // {
  //   title: 'Neomarm',
  //   stone: 'Акриловый камень',
  //   country: 'Тайвань',
  //   description: 'Бренд искусственного камня Neomarm (Неомарм) сравнительно новый на российском рынке отделочных материалов. Но изделия от него часто используется дизайнерами при создании различных элементов декора. Композитный материал производится с учетом санитарно-гигиенических норм и стандартов качества. Особая текстура имитирует фактуру итальянского мрамора. Изделия отличает досканальная точность изготовления, тонкие оттенки и цветовые переходы',
  //   imgURL: Quartz[2],
  // },
  {
    title: 'Grandex',
    stone: 'Акриловый камень',
    country: 'Юж. Корея',
    description: 'Акриловый камень премиум-класса, который официально производится в Южной Корее. В палитре искусственного камня Grandex собраны самые лучшие, самые востребованные цвета. Из акрилового камня grndx.ru можно создавать бесшовные и сложные пластичные формы. Узкая специализация, инновационные технологии и оборудование последнего поколения позволили создать материал, который обоснованно занял свое место в рядах лучших мировых брендов акрилового камня',
    imgURL: Quartz[2],
    image: grandex,
    id: 'grandex',
  },
]

// const data3 = [
//   {
//     title: 'Kerama Marazzi',
//     stone: 'Керамический камень',
//     country: 'Россия',
//     description: 'Красивые и легкие в эксплуатации керамическая плитка KERAMA MARAZZI и керамический гранит украсят собою фасады, полы и внутреннюю поверхность стен. Обладая надежными прочностными характеристиками и повышенной износостойкостью, керамическая плитка КЕРАМА МАРАЦЦИ заслуженно пользуется признанием мастеров-кафельщиков, ежедневно работающих с подобными отделочными материалами, и непосредственно покупателей, которые уже удостоверились в отличных свойствах плитки КЕРАМА МАРАЦЦИ',
//     imgURL: Quartz[2]
//   },
//   {
//     title: 'Laminam',
//     stone: 'Керамический камень',
//     country: 'Италия',
//     description: 'LAMINAM возникла в 2001 году благодаря новаторам керамической промышленности, которые придумали это изделие, создав для него эффективную технологию',
//     imgURL: Quartz[1]
//   },
// ]
interface ComponentProps {
  title: string,
  stone: string,
  country: string,
  description: string,
  imgURL: any,
  image: any,
  id: string
}

const StonePage: React.FC<ComponentProps> = ({title, stone, country, description, imgURL, image, id}) => {
  const location = useLocation()

    useEffect(() => {
        
        if (location.hash) {
          const targetElement = document.querySelector(location.hash);
          if (targetElement) {
            targetElement.scrollIntoView({ behavior: 'smooth' });
          }
        }
      }, [location]);
  return (
    <BackgroundImage id={id} src={imgURL} className='avarus-main' h={'max-content'}>
      <Container maw={1180}>
        <Group position='apart' h={'max-content'} py={'4rem'} className='avarus-group'>
          <Flex direction={'column'} className='avarus-flex'>
              <Text className='avarus-title'>
                {title}
              </Text>
              <Text my={'1rem'} className='avarus-type'>
                {stone}
              </Text>
              <Text className='avarus-country'>
                Страна: {country}
              </Text>
              <Text className='avarus-description'>
              {description}
              </Text>
              <motion.a href={`../stoleshnitsa/${id}`} className='avarus-button' whileHover={{ scale: 1.2 }} whileTap={{ scale: 0.8 }}>
                <Center>
                  <Text mt={'1.3rem'} ff={'Roboto'} fw={500}>
                  Перейти
                  </Text>
                </Center>
              </motion.a>
          </Flex>
          <BackgroundImage src={image} className='avarus-image'/>
        </Group>
      </Container>
    </BackgroundImage>
  )
}

export const StoleshnitsaPage: React.FC = () => {
  return (
    <Box w={'100%'} bg={'#FFF'} h={'max-content'}>
        <HeaderMegaMenu />
        <Container maw={1180}>
            <Group position='apart' mt={'10rem'} className='cataloge-back-group'>
                <Group position='left'>
                    <Box component='a' href='/#cataloge' sx={{textDecoration: 'none'}}>
                        <Flex direction={'row'}>
                            <IconArrowNarrowLeft size={'2.5rem'} color='#10222E' className='cataloge-back-arrow'/>
                            <Text ff={'Roboto'} fz={24} color='#10222E' fw={400} pt={2} ml={'1rem'} className='cataloge-back-text'>Назад</Text>
                        </Flex>
                    </Box>
                </Group>
                <Text ff={'Roboto Condensed'} fw={700} fz={42} color='#10222E' className='cataloge-back-title'>
                    Столешницы
                </Text>
            </Group>
        </Container>
        <Box className='quartz-main' py={'2rem'}>
          <Container maw={1180}>
           <Text className='quartz-title'>
            Кварцвевый камень
           </Text>
           <Text className='quartz-description'>
            Поверхность из кварцевого камня не впитывает воду и загрязнения, следовательно, на ней не остается инородных пятен от кофе, вина, растительного масла и многих других веществ, обычных в быту, на кухне, если из этого материала будет сделана столешница
           </Text>
          </Container>
        </Box>
        {data.map((item, index) => (
          <StonePage id={item.id} key={index} title={item.title} stone={item.stone} description={item.description} country={item.country} image={item.image} imgURL={item.imgURL}/>
        ))}
        <Container maw={1180}>
          <Divider size={4} color='#10222E' mt={'4rem'}/>
        </Container>
        <Box className='quartz-main' py={'2rem'}>
          <Container maw={1180}>
           <Text className='quartz-title'>
            Акриловый камень
           </Text>
           <Text className='quartz-description'>
           Огнестойкость. Простота обработки, возможность создания изделий сложных форм или большой площади без видимых швов. Так, акриловая столешница с интегрированной мойкой не только выглядит изящно, но и более гигиенична, так как не имеет бортиков и зазоров, где бы скапливалась влага и грязь
           </Text>
          </Container>
        </Box>
        {data2.map((item, index) => (
          <StonePage id={item.id} key={index} title={item.title} stone={item.stone} description={item.description} country={item.country} image={item.image} imgURL={item.imgURL}/>
        ))}
        {/* <Container maw={1180}>
          <Divider size={4} color='#10222E' mt={'4rem'}/>
        </Container> */}
        {/* <Box className='quartz-main' py={'2rem'}>
          <Container maw={1180}>
           <Text className='quartz-title'>
            Керамические плиты
           </Text>
           <Text className='quartz-description'>
           Выбор материалов для рабочих поверхностей на кухне насчитывает десятки вариантов. В числе самых популярных решений прочные позиции занимает полноформатная керамика. Натуральный состав, небольшой вес и идеально ровная поверхность делают керамические столешницы актуальным элементом отделки современной кухни
           </Text>
          </Container>
        </Box>
        {data3.map((item, index) => (
          <StonePage key={index} title={item.title} stone={item.stone} description={item.description} country={item.country} imgURL={item.imgURL}/>
        ))} */}
        <Container maw={1180}>
          <Divider size={4} color='#10222E' mt={'4rem'} mb={'4rem'}/>
        </Container>
        <Footer />
        <UpButton />
    </Box>
  )
}