import React from 'react'
import { BackgroundImage, Box, Button, Container, Flex, Group, Text } from '@mantine/core'
import { HeaderMegaMenu } from '../components/Header'
import { Footer } from '../components/Footer'
import { IconArrowNarrowLeft } from '@tabler/icons-react'
import { GostinayaData } from '../components/Gostinaya/GostinayaData'
import { UpButton } from '../components/UpButton'

interface ComponentProps {
  id: number,
  title: string,
  imgURL: any,
}

const PropsContainer: React.FC<ComponentProps> = ({id, title, imgURL}) => {
  return (
      <Box key={id} w={380} h={'max-content'} className="cataloge-component">
          <BackgroundImage w={'100%'} h={500} src={imgURL}/>
          <Box w={'100%'} h={100} bg={'#10222E'}>
              <Text ff={'Roboto Condensed'} color="#FFF" fz={20} lh={'normal'} p={'1rem'}>
                  {title}
              </Text>
          </Box>
      </Box>
  )
}

const GostinayaContainer: React.FC = () => {
  return (
    <>
    {GostinayaData.map((item) => (
      <PropsContainer key={item.id} id={item.id} title={item.title} imgURL={item.imgURL}/>
    ))}
    </>
  )
}

export const GostinayaPage: React.FC = (type: any) => {
  return (
    <Box w={'100%'} bg={'#FFF'} h={'max-content'}>
        <HeaderMegaMenu />
        <Container maw={1180}>
            <Group position='apart' mt={'10rem'} className='cataloge-back-group'>
                <Group position='left'>
                    <Box component='a' href='/#cataloge' sx={{textDecoration: 'none'}}>
                        <Flex direction={'row'}>
                            <IconArrowNarrowLeft size={'2.5rem'} color='#10222E' className='cataloge-back-arrow'/>
                            <Text ff={'Roboto'} fz={24} color='#10222E' fw={400} pt={2} ml={'1rem'} className='cataloge-back-text'>Назад</Text>
                        </Flex>
                    </Box>
                </Group>
                <Text ff={'Roboto Condensed'} fw={700} fz={42} color='#10222E' className='cataloge-back-title'>
                    Гостиные
                </Text>
            </Group>
            <Group position='left' mt={'4rem'} mb={'4rem'} w={'100%'} ml={'auto'} mr={'auto'} className='group-cataloge'>
              <GostinayaContainer />
            </Group>
        </Container>
        <Footer />
        <UpButton />
    </Box>
  )
}

