import { BackgroundImage, Box, Button, Container, Flex, Group, Text } from '@mantine/core'
import { HeaderMegaMenu } from '../components/Header'
import { Footer } from '../components/Footer'
import { IconArrowNarrowLeft } from '@tabler/icons-react'
import { ToiletCataloge } from '../components/Toilet/ToiletComponent'
import { UpButton } from '../components/UpButton'

export const ToiletPage: React.FC = () => {

  return (
    <Box w={'100%'} bg={'#FFF'} h={'max-content'}>
        <HeaderMegaMenu />
        <Container maw={1180}>
            <Group position='apart' mt={'10rem'} className='cataloge-back-group'>
                <Group position='left'>
                    <Box component='a' href='/#cataloge' sx={{textDecoration: 'none'}}>
                        <Flex direction={'row'}>
                            <IconArrowNarrowLeft size={'2.5rem'} color='#10222E' className='cataloge-back-arrow'/>
                            <Text ff={'Roboto'} fz={24} color='#10222E' fw={400} pt={2} ml={'1rem'} className='cataloge-back-text'>Назад</Text>
                        </Flex>
                    </Box>
                </Group>
                <Text ff={'Roboto Condensed'} fw={700} fz={42} color='#10222E' className='cataloge-back-title'>
                    Сан. Узлы
                </Text>
            </Group>
            
            <Group position='left' mt={'4rem'} mb={'4rem'} w={'100%'} ml={'auto'} mr={'auto'} className='group-cataloge'>
                <ToiletCataloge />
            </Group>
        </Container>
        <Footer />
        <UpButton />
    </Box>
  )
}