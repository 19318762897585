import React from 'react'
import { Box, Center, Container, Grid, Text, } from '@mantine/core'
import { CatalogeCarousel } from './CatalogeCarousel';

export const SectionThree: React.FC = (type: any) => {
  return (
    <Box w={'100%'} h={'max-content'} id='cataloge' className='sec-3-main'>
      <Container maw={1180}>
        <Center>
          <Box w={440} h={65} mt={'4rem'} sx={{borderBottomColor: '#10222E', borderBottomWidth: 4, borderBottomStyle: 'solid'}} className='sec-3-box'>
            <Center>
              <Text ff={'Roboto Condensed'} fz={42} color='#10222E' fw={700} lh={'normal'} lts={2} className='sec-3-text'>
                Каталог мебели
              </Text>
            </Center>
          </Box>
        </Center>
          <Grid mt={'5rem'} pb={'6rem'} className='sec-3-grid'>
            <CatalogeCarousel />
          </Grid>
      </Container>
    </Box>
  )
}
