import React from "react";
import { createStyles, keyframes } from "@mantine/core";

export const useStyles = createStyles((theme) => ({
    button: {
        backgroundColor: '#6C725A',
    }
}))

export const changeColorButton = keyframes({
    'from': { backgroundColor: '#6C725A' },
    'to': { backgroundColor: '#73303F' }
})
export const changeColorButton2 = keyframes({
    'from': {
        backgroundColor: 'rgba(1,1,1,0)',
        color: '#FFF'
    },
    'to': {
        backgroundColor: '#FFF',
        color: '#10222E'
    }
})
export const changeColorButton3 = keyframes({
    'from': {
        backgroundColor: '#10222E',
        color: '#FFF'
    },
    'to': {
        backgroundColor: '#FFF',
        color: '#10222E'
    }
})
export const changeColorButton4 = keyframes({
    'from': { backgroundColor: '#10222E' },
    'to': { backgroundColor: '#73303F' }
})