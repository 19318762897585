import { useParams } from 'react-router-dom'
import { HeaderMegaMenu } from '../Header'
import { Footer } from '../Footer'
import { Box, Container, Flex, Group, Image, Text } from '@mantine/core'
import { Carousel } from '@mantine/carousel'
import { GrandexData } from './StoleshnitsaData'
import { IconArrowLeft, IconArrowRight, IconArrowNarrowLeft } from '@tabler/icons-react'
import './Stoleshnitsa.css'

type Props = {}

export const Grandex: React.FC = (props: Props) => {
    const { pathURL } = useParams()

  return (
    <>
    <HeaderMegaMenu />
      <Box h={'max-content'} pb={'6rem'}>
       <Container maw={1180}>
       <Group position='apart' mt={'10rem'} className='cataloge-back-group'>
          <Box component='a' href='../grandex' sx={{textDecoration: 'none'}}>
            <Flex direction={'row'}>
              <IconArrowNarrowLeft size={'2.5rem'} color='#10222E' className='cataloge-back-arrow'/>
                <Text ff={'Roboto'} fz={24} color='#10222E' fw={400} ml={'1rem'} pt={2} className='cataloge-back-text'>Назад</Text>
            </Flex>
          </Box>
        </Group>
        {GrandexData
           .filter((item) => item.pathURL == pathURL)
           .map((item, index) => (
            <Group position='center'>
              <Box className='stoleshnitsa-main-inside'>
                 <Text key={index} ff={'Roboto Condensed'} mb={'2rem'} fw={500} fz={24} color='#10222E'>
                   {`${item.pathURL} ${item.title}`}
                </Text>
              <Carousel 
                previousControlIcon={<IconArrowLeft color='#FFF' size={48}/>}
                nextControlIcon={<IconArrowRight color='#FFF' size={48}/>}
                >
                {item.photoArray.map((item, index) => (
                <Carousel.Slide>
                    <Image src={item}/>
                </Carousel.Slide>    
                ))}
              </Carousel>
              </Box>
              <Box className='stoleshnitsa-project-main'>
                <Text className='stoleshnitsa-project-title'>
                  {`${item.pathURL} ${item.title}`}
                </Text>
                <Text className='stoleshnitsa-project-collection'>
                  Коллекция
                </Text>
                <Text className='stoleshnitsa-project-collection-title'>
                  {item.collection}
                </Text>
                <Text className='stoleshnitsa-project-collection-type'>
                  Тип поверхности
                </Text>
                <Text className='stoleshnitsa-project-collection-type-title'>
                  {item.type}
                </Text>
                <Text className='stoleshnitsa-project-collection-size'>
                  Размеры слябов
                </Text>
                {item.size.map((size, index) => (
                  <Text className='stoleshnitsa-project-collection-size-title' key={index}>{size}</Text>
                ))}
              </Box>
            </Group>
            ))}
       </Container>
      </Box>
    <Footer />
    </>
  )
}