import { BackgroundImage, Box, Container, Text } from "@mantine/core"
import { Carousel } from "@mantine/carousel"
import { Blocks } from "../../content/Blocks"
import { dataSectionFive } from "../../content/data"
import { IconArrowLeft, IconArrowRight } from "@tabler/icons-react"
// import { useLocation } from 'react-router-dom'
// import { useEffect } from "react"

interface ComponentProps {
    id: number,
    title: string,
    material: string,
    description: string,
    photoURL: any,
}

const PropsContainer: React.FC<ComponentProps> = ({title, material, description, photoURL, id}) => {
    // const location = useLocation()

    // useEffect(() => {
        
    //     if (location.hash) {
    //       const targetElement = document.querySelector(location.hash);
    //       if (targetElement) {
    //         targetElement.scrollIntoView({ behavior: 'smooth' });
    //       }
    //     }
    //   }, [location]);

    return (
        <Box w={580} h={'max-content'} key={id} className="sec-5-box">
            <a href={`portfolio/${id}`} style={{width: '100%', height: '100%', textDecoration: 'none'}}>
                <BackgroundImage w={'100%'} h={434} src={photoURL} className="sec-5-image"/>
                    <Box w={'100%'} h={126} bg={'#6C725A'}>
                        <Text m={'1rem'} pt={'1rem'} color="#FFF" fz={24} ff={'Roboto Condensed'} fw={700} lh={'normal'} className="sec-5-title">
                            {title}
                        </Text>
                        <Text ml={'1rem'} color="#FFF" fz={18} ff={'Roboto Condensed'} lh={'normal'} className="sec-5-material">
                            <b>Материал : </b>
                            {material}
                        </Text>
                        <Text ml={'1rem'} mt={8} color="#FFF" fz={18} ff={'Roboto Condensed'} lh={'normal'} className="sec-5-description">
                            {description}
                        </Text>
                    </Box>
            </a>
        </Box>
    )
}

export const SectionFive: React.FC = (type: any) => {
    return (
        <Box w={'100%'} h={'max-content'} bg={'#FFF'} pb={24} id="portfolio" className="sec-5-main">
            <Container maw={1180}>
                <Text color="#10222E" mt={'4rem'} ff={'Roboto Condensed'} fw={700} fz={42} lh={'normal'} lts={2}>
                    Наши работы
                </Text>
                <Blocks />
                    <Carousel
                        mah={576}
                        w={'100%'}
                        mt={'2rem'}
                        slideSize={'50%'}
                        slidesToScroll={1}
                        loop
                        previousControlIcon={<IconArrowLeft color='#FFF' size={42}/>}
                        nextControlIcon={<IconArrowRight color='#FFF' size={42}/>}
                        controlsOffset={'xl'}>
                        {dataSectionFive.map((item) => (
                            <Carousel.Slide mx={'sm'} key={item.id}>
                                <PropsContainer id={item.id} title={item.title} material={item.material} description={item.description} photoURL={item.photoURL} />
                            </Carousel.Slide>
                        ))}
                    </Carousel>
                {/* </Box> */}
            </Container>
        </Box>
    )
}